<template>
    <CreateAccount />
</template>


<script>
import CreateAccount from '@/components/CreateAccount';

export default {
  components: {
    CreateAccount
  }
};
</script>

<style scoped>
.logo-form {
  width: 100px;
  margin-left: 35%;
  margin-top: 20px;
  height: 100px;
}
</style>