<template>
  <v-container>
    <v-card :loading="loading" class="mx-auto my-12" max-width="374">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-card-title>
        Alterar Configuração
      </v-card-title>

      <v-form @submit.prevent="submit" ref="form">
        <v-card-text>
          <v-alert type="success" v-if="successMsg">
            Configuração salva com sucesso!
          </v-alert>
          <v-row align="center" class="mx-0">
             <v-select
              v-model="form.payment_mode"
              :items="paymentModes"
              item-text="title"
              item-value="id"
              label="Selecione o tipo de pagametno"
              outlined
              dense
            ></v-select>
          
          </v-row>
         <v-row align="center" class="mx-0">
            <v-text-field
              v-model="form.version"
              label="Versao do app"
              :rules="[(v) => !!v || 'Versao do app è obrigatorio']"
              hide-details="auto"
            ></v-text-field>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-btn block type="submit" color="primary">
            <span v-if="loading"> Carregando </span>
            <span v-else> Enviar </span>
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>


<script>

export default {
      async mounted(){
          let url =
          "/current_config";
        const resp = await this.$api.get(url);
        this.form.version = resp.data.version
        this.form.payment_mode = resp.data.payment_mode
    },
  methods: {
  
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let url =
          "/configurations";

        const resp = await this.$api.post(url, {
          configuration: {...this.form},
        });
        if (resp.status === 200) {
          this.loading = false;
          this.successMsg = true;
        } else {
          this.loading = false;
        }
      }
    },
  },
  data: () => ({
    formValid: true,
    loading: false,
    successMsg: false,
    paymentModes: [
        {
            title: "Web", id: "web"
        },
        {
            title: "In App", id: "in_app"
        }
    ],
    validations: {},
    configs: {
        version: "",
        payment_mode: ""
    },
    form: {
     payment_mode: "",
     version: ""
    },
  }),
};
</script>

<style scoped>
.logo-form {
  width: 100px;
  margin-left: 35%;
  margin-top: 20px;
  height: 100px;
}
</style>