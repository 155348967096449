<!-- Modal.vue -->
<template>
  <div class="modal">
    <div class="modal-content">
      <!-- Conteúdo do modal -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["closeModal"],
  data() {
    return {
      showModal: true,
    };
  },

  methods: {
    openModal() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
/* Estilos para o modal (adicione conforme necessário) */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  z-index: 999999;
  justify-content: center;
}

.modal-content {
  width: 50%;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
}
</style>
