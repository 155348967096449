<template>
  <div v-if="!loading">

    <v-card v-if="isPopulate" class="cardSubscription">

      <v-card-title class="cardSubscription-title">
        <div style="display: flex; justify-content: space-between; width: 100%">
          <span class="headline">{{ plansObj.title }}</span>
          <ModalChangePlan :plansObj="plansObj" :cancel="false" v-if="changePlan && !create" @closedModal="handleClosedModal">
            <template v-slot:activator>
              <v-btn @click="handleModal(plansObj)" class="cardSubscription-btn">{{ (currentPlan != null && currentPlan.idPlan == plansObj.id) ?
                'Plano Atual' : 'Trocar' }}</v-btn>
            </template>
          </ModalChangePlan>
        </div>
        
        <template v-if="create">
          <v-btn @click="functionParam()" class="cardSubscription-btn">Escolher</v-btn>
        </template>
        <template v-if="account">
          <v-btn @click="functionParam()" class="cardSubscription-btn">Plano Atual</v-btn>
        </template>
        <template v-if="coupon">
          <v-btn @click="handleModal(plansObj)" class="cardSubscription-btn">Aplicar</v-btn>
        </template>
      </v-card-title>



      <v-card-text class="cardSubscription-price">
        <div class="text-center">
          <div class="headline" style="font-size: 24px !important;">R$ {{ plansObj.value.toFixed(2) }}</div>
          <div class="caption">{{ plansObj.months == 1 ? 'POR MÊS' : plansObj.months == 3 ? 'POR TRIMESTRE' : 'POR ANO' }}
          </div>
        </div>
      </v-card-text>

      <v-list v-if="plansObj.benefits != null" class="cardSubscription-flat">


        <v-list-item v-for="item in plans" :key="item.id" class="cardSubscription-flat-listItem">
          <v-list-item-icon>
            <v-icon>mdi-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{ item }}</v-list-item-content>
        </v-list-item>


      </v-list>
    </v-card>

    <v-card v-if="!isPopulate" class="cardSubscription">
      <v-card-title class="cardSubscription-title">
        <div style="display: flex; justify-content: space-between; width: 100%">

        <template v-if="changePlan">
          <span class="headline" style="word-break: break-word"> Cancelar assinatura </span>
        </template>
        <template v-if="!changePlan">
          <span class="headline" style="word-break: break-word">Acesso a aulas e conteúdos gratuitos</span>
        </template>
        <ModalChangePlan v-if="changePlan" :cancel="true" :plansObj="null" @closedModal="handleClosedModal">
          <template v-slot:activator>
            <v-btn @click="openModal(plansObj)" class="cardSubscription-btn">
              {{ currentPlan == null ?
                'Plano Atual' : 'Cancelar' }}
            </v-btn>
          </template>
        </ModalChangePlan>
        <template v-if="create && !changePlan">
          <v-btn @click="functionDefault()" class="cardSubscription-btn">Escolher</v-btn>
        </template>
        <template v-if="account">
          <v-btn @click="functionParam()" class="cardSubscription-btn">Plano Atual</v-btn>
        </template>
        </div>
      </v-card-title>

      <v-card-text class="cardSubscription-price bgPrice">
        <div class="text-center">
          <div class="headlineAlone">Acesso apenas a aulas e conteúdos gratuitos</div>
<!-- 
          <template v-if="changePlan && !create">
            <div class="headlineAlone">Cancelar assinatura atual</div>
          </template>
          <template v-if="create">
          </template> -->
        </div>
      </v-card-text>

      <v-list class="cardSubscription-flat">


        <v-list-item class="cardSubscription-flat-listItem">
          <v-list-item-icon>
            <v-icon>mdi-check</v-icon>
          </v-list-item-icon>
          <template v-if="create">
            <v-list-item-content>Prossiga nessa opção para realizar o cadastro e acesso ao app</v-list-item-content>
          </template>
          <template v-if="changePlan">
            <v-list-item-content>Mudar para a opção sem assinatura cancela seu plano atual</v-list-item-content>
          </template>
        </v-list-item>


      </v-list>
    </v-card>

    <v-snackbar v-model="showToast" :color="toastColor" :timeout="3000">
      {{ messageToast }}

      <template v-slot:actions>
          <v-btn color="white" variant="text" @click="showSuccessshowToastToast = false">
              Close
          </v-btn>
      </template>
  </v-snackbar>
  </div>
</template>

<script>


export default {
  name: 'CardSubscription',
}
</script>

<script>
import ModalChangePlan from '@/components/shared/ModalChangePlan.vue'
import router from '../../../router/index'

export default {
  components: {
    ModalChangePlan
  },
  props: 
    ['onclick',
    'isPopulate',
    'plansObj',
    'changePlan',
    'create',
    'account',
    'coupon'
    ],
    methods: {
      async init() {
        this.plans = await this.plansObj.benefits.split(";");
      },
      openModal(plano) {
        console.log('Open modal: ', this.currentPlan, plano);

      },
      handleClosedModal(event) {
        console.log('Event closed: ', event);
        this.showToast = true;
        if(event) {
          this.toastColor = 'green';
          this.messageToast = 'Assinatura alterada com sucesso!';
        } else {
          this.toastColor = 'red';
          this.messageToast = 'Algo deu errado. Tente novamente mais tarde!';
        }
      },
      handleModal(plano) {
        if(this.currentPlan === null) {
          router.push(`/NewSubscription/${plano.id}`)
        } else {
          this.openModal(plano);
        }
      },
      async getPlan(){
        const plan = await localStorage.getItem("plan");
        this.currentPlan = JSON.parse(plan);
        this.init()
        this.loading = false

      },
      functionParam(){
        this.onclick(this.plansObj.id);
      },

      functionDefault() {
        this.onclick();
      }

    },
    
    data() {
      return {
        plans: [],
        currentPlan: null ,
        loading: true,
        planSelected: null,
        showToast: false,
        messageToast: '',
        toastColor: 'green'
      }
    },
    
    created() {
      this.getPlan();
    },


}

</script>
<style scoped>
.cardSubscription {
  /*max-width: 350px;
  min-width: 350px;
  /*min-height: 363px;*/
  height: 100%;
  padding: 30px;
  box-shadow: none !important;
}

.cardSubscription-title {
  padding: 0px;
  margin-bottom: 20px;
  flex-wrap: nowrap !important;
}

.cardSubscription-title span {
  color: #131313;
  font-size: 22px;
  font-weight: bold;
  margin-right: 18px;
}

.cardSubscription-btn {
  max-width: 110px;
  min-height: 40px;
  border-radius: 20px;
  background: #CEF9C6 !important;
  border: 1px solid #1D201C !important;
}

.cardSubscription-price {
  background: #FFF8DD;
  border-radius: 10px;
  padding: 18px 0;
  margin-bottom: 20px;
}

.bgPrice {
  background: #C5E9C3 !important;
}

.cardSubscription-flat {
  background: #F7F6F3;
  border-radius: 10px;
  padding: 25px 20px;
}

.cardSubscription-flat-listItem {
  padding: 0px;
  min-height: unset;

}

@media (max-width: 1023px) {
  .cardSubscription {
    padding: 15px;
  }
}
</style>
<style>
.cardSubscription-btn span {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
}

.v-application .headline {
  font-size: 1em !important;
}

.headline {
  word-break: break-word;
}

.cardSubscription-price .headline {

  font-weight: bold;
  color: #131313;
  margin-bottom: 20px;
}

.cardSubscription-price .headlineAlone {
  font-weight: bold;
  color: #131313;
  padding: 10px 0;
}

.cardSubscription-price .caption {
  background: #E3EFFE;
  border-radius: 10px;
  max-width: 112px;
  min-height: 20px;
  margin: 0 auto;
  font-size: 12px;
  color: #1975D0;
  font-weight: 600;
}

.cardSubscription-flat-listItem .v-list-item {
  align-items: flex-start;
}

.cardSubscription-flat-listItem .v-list-item__content {
  padding: 0px;
  font-size: 18px;
  font-weight: 600;
  color: #131313;
}

.cardSubscription-flat-listItem .v-list-item__icon {
  margin: 0;
  margin-right: 5px !important;
  background: #CEF9C6;
  border-radius: 4px;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.cardSubscription-flat-listItem .v-list-item__icon i {
  font-size: 15px;
  font-weight: bold;
  justify-content: center;
}

.cardSubscription-flat-listItem .v-list-item__icon i::before {
  font-weight: bold;
  color: #000;
}

.cardSubscription-flat-listItem:not(:last-child) {
  margin-bottom: 5px;
}
</style>