<template>
  <v-container class="containerLogin-form">
    <v-card class="containerLogin-FormCard">
      <div class="profile-picture">
        <img height="120px" width="120px" :src="avatar" alt="Imagem do perfil" />
        <!-- <img height="120px" width="120px" src="@/assets/image_profile.png" alt="Imagem do perfil"> -->
        <div class="button-container">
          <button class="profile-button">Escolher</button>
        </div>
      </div>

      <v-form @submit.prevent="submit" ref="form" class="form">
        <v-container class="containerForm">
          <v-row class="containerForm-row">
            <v-col cols="12" class="containerForm-col">
              <v-label class="containerForm-label">Nome</v-label>
              <v-text-field class="containerForm-input" v-model="form.first_name" solo></v-text-field>
            </v-col>
            <v-col cols="12" class="containerForm-col">
              <v-label class="containerForm-label ">E-mail</v-label>
              <v-text-field :readonly="true" class="containerForm-input" :append-icon="'mdi-lock'" v-model="email"
                solo></v-text-field>
            </v-col>
            <v-col cols="12" class="containerForm-col">
              <v-label class="containerForm-label">Data de Nascimento</v-label>
              <v-text-field class="containerForm-input" solo v-model="form.birthdate"></v-text-field>
            </v-col>
            <v-col cols="12" class="containerForm-col">
              <v-label class="containerForm-label">Telefone</v-label>
              <v-text-field class="containerForm-input" v-model="form.phone" solo></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-row class="justify-center">
          <v-col sm="12" lg="10">
            <v-btn :loading="showLoader" type="submit" block class="containerForm-btn">Salvar</v-btn>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col sm="12" lg="10">
            <router-link class="containerForm-link" to="/account-v2">Cancelar</router-link>
          </v-col>
        </v-row>
        <v-snackbar :color="snackbar.type" v-model="snackbar.show">
          {{ snackbar.message }}

          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">
              Fechar
            </v-btn>
          </template>
        </v-snackbar>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      showLoader: false,
      form: {
        first_name: "",
        birthdate: "",
        phone: "",
      },
      textColor: "#00FF00",
      email: "",
      avatar: "",
      idUser: "",
      snackbar: {
        show: false,
        type: 'success',
        message: ''
      }
    };
  },
  methods: {
    ...mapActions("User", ["UPDATE_USER"]),
    getUserbyStorage() {
      const resp = JSON.parse(localStorage.getItem("user"));
      (this.form.first_name = resp.first_name),
        (this.email = resp.email),
        (this.form.birthdate = resp.birthdate),
        (this.form.phone = resp.phone);
      this.avatar = resp.avatar;
      this.idUser = resp.id;
    },
    showSuccessSnackbar() {
      this.snackbar.message = 'Dados alterados com sucesso!',
        this.snackbar.type = 'success';
      this.snackbar.show = true;
    },
    showErrorSnackbar() {
      this.snackbar.type = 'error';
      this.snackbar.message = 'Algo deu errado, confira os dados e tente novamente.',
        this.snackbar.show = true;
    },
    setStorage(body) {
      const json = JSON.stringify(body);
      console.log(json);
      localStorage.setItem("user", json);
    },
    async submit() {
      this.showLoader = true;
      const resp = await this.UPDATE_USER({
        form: this.form,
        idUser: this.idUser,
      });

      console.log('Resp update: ', resp);

      this.showLoader = false;
      if (resp) {
        this.setStorage(resp);
        this.showSuccessSnackbar();
      } else {
        this.showErrorSnackbar();
      }

      console.log(this.form);
    },
  },
  created() {
    this.getUserbyStorage();
  },
};
</script>

<style scoped>
.containerLogin-form {
  padding: 20px;
}

.containerLogin-FormCard {
  max-width: 730px;
  margin: 0 auto;
  padding: 40px;
  border-radius: 8px;
}

.profile-picture {
  position: relative;
  height: 120px;
  width: 120px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.profile-picture img {
  border-radius: 50%;
}

.profile-button {
  position: absolute;
  bottom: -3px;
  right: -2px;
  background-image: url("../../../../assets//mask_file.png");
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 7px;
  font-size: 12px;
  font-weight: bold;
  color: #131313;
}

.containerForm-btn {
  min-height: 50px;
  background-color: #cef9c6 !important;
  border-radius: 25px;
  box-shadow: none;
  margin-bottom: 20px;
}

.containerForm-label {
  font-weight: bold;
}

.containerForm-input {
  width: 100%;
}

.containerForm-input .v-input__slot {
  background-color: #131313 !important;
}

.containerForm {
  max-width: 540px;
}

.containerForm-col {
  padding: 0;
}

.containerForm-col label {
  font-size: 16px;
  font-weight: bold;
  color: #131313;
}

.containerForm-link {
  font-size: 14px;
  font-weight: bold;
  color: #131313;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
}

.divLoader {
  margin: 1rem 0 1rem 0;
  display: flex;
  justify-content: center;
}
</style>
