<template>
  <v-container style="margin-top: 10px;">
    <v-form>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field v-model="dataIni" label="Data Inicial" type="date" prepend-icon="mdi-calendar"></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="dataFin" label="Data Final" type="date" prepend-icon="mdi-calendar"></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-row align="end" justify="end">
      <v-btn color="warning" style="margin: 5px" depressed @click="downloadReport()" :disabled="disabledButton">
        Exportar
      </v-btn>
      <v-btn color="primary" style="margin: 5px" depressed @click="filterCoupons" :disabled="disabledButton">
        Filtrar
      </v-btn>
      
    </v-row>
    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details
      style="margin-top: 10px; margin-bottom: 10px">
      \ \
    </v-text-field>
    <template>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="cupomList"
        :items-per-page="10"
        :search="search"
        :expanded.sync="expanded"
        @item-expanded="loadDetails"
        class="elevation-5"
        show-expand
      >
      <template v-slot:item.plan_total="{ item }">
        R$ {{ parseFloat(item.plan_total).toFixed(2) }}
      </template>

      <template v-slot:item.discount_total="{ item }">
        R$ {{ parseFloat(item.discount_total).toFixed(2) }}
      </template>

      <template v-slot:item.final_saved="{ item }">
        R$ {{ parseFloat(item.final_saved).toFixed(2) }}
      </template>
        <template v-slot:expanded-item="">
          <td :colspan="1">

          </td>
          <td :colspan="5" class="" style="padding: 0;">
            <v-data-table
              style="border-radius: 0;"
              :loading="loadingUsers"
              :headers="innerHeaders"
              :items="users"
              :items-per-page="users.length"
              :hide-default-footer="true"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td class="cursor" @click="$router.push(`/editUser/${item.id}`)" >{{ item.first_name }}</td>
                  <td class="cursor" @click="$router.push(`/editUser/${item.id}`)">{{ item.email }}</td>
                  <td>{{ item.plan }}</td>
                  <td>R$ {{ parseFloat(item.plan_total).toFixed(2) }}</td>
                  <td>R$ {{ parseFloat(item.discount_total).toFixed(2) }}</td>
                  <td>R$ {{ parseFloat(item.final_saved).toFixed(2) }}</td>
                </tr>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </template>
  </v-container>
</template>

<style>
.custom-table th,
.custom-table td {
  padding: 0 120px;
  /* Define o preenchimento personalizado */
}

.cursor {
  cursor: pointer;
}

.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
<script>
import moment from "moment";
import { getListReportCoupons, getUsers } from "@/services/couponService";

export default {
  methods: {
    toggleRowExpansion(item) {
      item.expanded = !item.expanded;
    },
    async loadDetails({ item }) {
      console.log(item);
      const resp = await getUsers(this.dataIni, this.dataFin, item.id);
      this.loadingUsers = false;
      this.users = resp.body.users;
      console.log(resp.body.users);
    },
    async filterCoupons() {
      this.loading = true;
      const resp = await getListReportCoupons(this.dataIni, this.dataFin);
      // console.log("Resp: ", resp);
      this.loading = false;
      this.cupomList = resp.body.report;
    },
    async downloadReport() {
      this.loading = true;
      this.disabledButton = true;
      const url = `/export_payment_coupons?initial_date=${this.dataIni}&final_date=${this.dataFin}`;
      this.$api.get(url).then((response) => {
        this.loading = false;
        this.disabledButton = false;

        let mediaType="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        const url = mediaType + response.data;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'relatorio_cupons.xlsx'); // Set the filename for the downloaded file
        document.body.appendChild(link);
        link.click();
      }).catch(error => {
        this.loading = false;
        this.disabledButton = true;
        console.error('Error downloading Excel file:', error);
      });
    },
  },
  async mounted() {
    this.filterCoupons();
  },
  data() {
    return {
      loadingUsers: false,
      innerHeaders: [
        { text: "Usuário", value: "first_name" },
        { text: 'Email', value: "email"},
        { text: "Plano", value: "plan" },
        { text: "Valor total", value: "plan_total"},
        { text: "Valor desconto", value: "discount_total" },
        { text: "Valor pago", value: "final_saved"}
      ],
      expanded: [],
      singleExpand: false,
      search: "",
      users: [],
      activePicker: null,
      dataIni: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      dataFin: moment().format("YYYY-MM-DD"),
      paymentStatusFilter: "",
      menu1: false,
      menu2: false,
      paymentStatusType: [
        { id: "", title: "Todos" },
        { id: "approved", title: "Pago" },
        { id: "not_payed", title: "Não Pago" },
      ],
      cupomList: [],
      loading: false,
      disabledButton: false,
      approvedStatus: ["approved", "PAYMENT_CONFIRMED"],
      headers: [
        { text: "Cupom", value: "description" },
        { text: "Valor Total", value: "plan_total" },
        { text: "Valor Desconto total", value: "discount_total" },
        { text: "Valor Pago", value: "final_saved" },
      ],
    };
  },
};
</script>
