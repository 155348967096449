<template>
  <v-container>
    <v-btn color="success" @click="$router.push('/createLevel')">
      Adicionar Nível
      <v-icon>
        mdi-plus
      </v-icon>
    </v-btn>
    <v-alert v-if="message !== ''" type="success" >
      {{message}}
    </v-alert>
    <br />
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="levels"
      :items-per-page="5"
      class="elevation-1"
    >
    <template v-slot:item.image="{ item }">
      <img width="150" height="100" :src="item.image" />
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="$router.push(`/editLevel/${item.id}`)"
      >
        mdi-pencil
      </v-icon>
      <ModalDelete  v-if="item.deletable" @click="deleteLevel(item.id)" />
    </template>    
    </v-data-table>
  </v-container>
</template>
<script>
import ModalDelete from '@/components/shared/ModalDelete';

import { getClassLevels } from '@/services/classLevelService';

export default {
  components: {
    ModalDelete
  },
  methods: {
   async deleteLevel(levelId){
      let url = `class_levels/${levelId}`;

      this.$api.delete(url).then(() => {
        this.levels = this.levels.filter((item) => item.id !== levelId)
        this.message = "Nível excluído com sucesso!"
        setTimeout(() => {
          this.message = ""
        }, 1200)
      }).catch(err=> {
        console.log(err)
        alert("erro ao deletar");
      }).finally(() => this.dialog = false);

    }
  },
  data() {
    return {
      levels: [],
      loading: false,
      message: "",
      headers: [
        { text: "Id", value: "id" },
        { text: "Titulo", value: "level_name" },
        { text: "Descrição", value: "description" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  async mounted() {
    this.loading = true
    const resp = await getClassLevels()
    this.loading = false
    this.levels = resp.data;
  },
};
</script>