<template>
  <div>
    <Layout>
      <template #content>
        <div v-if="card.length > 0" class="containerManageSubscription">
          <Card :changePlan="true" :onclick="teste" :isPopulate="true" :plansObj="i" v-for="i in card" :key="i" />
          <Card :isPopulate="false" :changePlan="true"/>
        </div>


      </template>
    </Layout>

  </div>
</template>

<script>
import Layout from '@/layouts/HomeLayout.vue';
import Card from '@/components/Layout/CardSubscription/CardSubscription.vue'
import { mapActions } from 'vuex';

export default {
  components: {
    Layout,
    Card,
  },

  data() {
    return {
      card: []
    }
  },
  methods: {
    ...mapActions("Plan", ["REQUEST_PLANS"]),
    async getList() {
      const plan = JSON.parse(localStorage.getItem('plan'));
      const resp = await this.REQUEST_PLANS()
      this.card = resp;

      if(!!plan && !plan.is_unique && !plan.is_expired) {
        this.card = this.card.filter(plan => plan.permit_installments == false)
      }

    },
    teste() {
      console.log(`teste`)
    }
  },

  created() {
    this.getList()
  },
};
</script>
<style scoped>

.containerManageSubscription {
  /*padding: 40px 0 40px 35px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;*/
  display: grid;
  gap: 1rem;

}

@media (min-width: 600px) {
  .containerManageSubscription { 
    grid-template-columns: repeat(2, 1fr); 
   }
}

/* Screen larger than 900px? 3 columns */
@media (min-width: 900px) {
  .containerManageSubscription { 
    grid-template-columns: repeat(3, 1fr);
    padding: 40px 60px 40px 60px;
    margin-inline: 40px;
  }
}

</style>