<template>
    <EditClassItem />
</template>


<script>
  import EditClassItem from "@/components/ClassItem/editClassItem.vue"
  export default {
      components: {
        EditClassItem
      }
  }
</script>