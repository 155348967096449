<template>
    <CouponSubscription></CouponSubscription>
</template>
  
  
<script>
import CouponSubscription from '@/components/CouponSubscription.vue';

export default {
    components: {
        CouponSubscription,
    },
    data() {
        return {}
    },
    methods: {}

}
</script>
  
<style>
*,
p,
h2,
h3,
h4 {
    font-family: "Roboto", sans-serif !important;
}
</style>