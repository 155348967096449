import { render, staticRenderFns } from "./CreateLevel.vue?vue&type=template&id=66e6a734&scoped=true"
import script from "./CreateLevel.vue?vue&type=script&lang=js"
export * from "./CreateLevel.vue?vue&type=script&lang=js"
import style0 from "./CreateLevel.vue?vue&type=style&index=0&id=66e6a734&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66e6a734",
  null
  
)

export default component.exports