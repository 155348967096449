<template>
    <ListCategories />
</template>


<script>
  import ListCategories from "@/components/Categories/ListCategories";
  export default {
      components: {
          ListCategories
      }
  }
</script>