<template>
  <v-container>
    <v-card :loading="loading" class="mx-auto my-12" max-width="374">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <v-card-title>
        Criar Usuário
      </v-card-title>

      <v-form @submit.prevent="submit" ref="form">
        <v-card-text>
          <div v-if="successMsg">
            <v-alert type="success" >
              Usuário criado com sucesso!
            </v-alert>
            <v-btn @click="$router.push(`/users`)">
              <v-icon>
                mdi-arrow-left-circle
              </v-icon>
              Todos usuários
            </v-btn>
          </div>
          <v-row v-else align="center" class="mx-0">
            <v-text-field
              v-model="form.name"
              label="Nome"
              :rules="[(v) => !!v || 'Nome é obrigatório']"
              hide-details="auto"
            ></v-text-field><br />
            <v-text-field
              v-model="form.email"
              label="Email"
              :rules="[(v) => !!v || 'Email é obrigatório']"
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              v-model="form.password"
              @click:append="togglePassword"
              :append-icon="this.passwordType === 'password' ? 'mdi-eye' : 'mdi-eye-off' "
              label="Senha"
              :type="passwordType"
              :rules="[(v) => !!v || 'Senha é obrigatória']"
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              v-model="form.phone"
              :rules="[(v) => !!v || 'Telefone é obrigatório']"
              v-mask="['(##)#####-####']"
              label="Telefone"
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              v-model="form.birthdate"
              :rules="[(v) => !!v || 'Nascimento é obrigatório']"
              v-mask="['##/##/####']"
              label="Nascimento"
              hide-details="auto"
            ></v-text-field>
            <br />
            <v-radio-group
              :rules="[(v) => !!v || 'Selecione uma categoria']"
              v-model="form.gender"
            >
              <v-radio :label="`Masculino`" value="male"></v-radio>
              <v-radio
                :label="`Feminino`"
                value="female"
              ></v-radio> </v-radio-group
            ><br />
            <v-checkbox
              v-model="form.weflow_team"
              :label="`Time WeFlow`"
            ></v-checkbox><br />

            <v-checkbox
              v-model="form.active"
              :label="`Usuário ativo`"
            ></v-checkbox>
            <v-select
              v-model="form.user_type"
              :items="userTypes"
              item-text="title"
              item-value="id"
              label="Selecione o tipo do usuário"
              outlined
              dense
            ></v-select>
          </v-row>
        </v-card-text>

        <v-card-text v-if="!successMsg">
          <v-btn block type="submit" color="primary">
            <span v-if="loading"> Carregando </span>
            <span v-else> Enviar </span>
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>


<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("User", ["LOGIN_USER"]),
    togglePassword(){
      if(this.passwordType === "password"){
        this.passwordType = "type";
      }else{
        this.passwordType = "password";
      }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let url =
          "/users";

        const resp = await this.$api.post(url, {
          user: {
            first_name: this.form.name,
            password: this.form.password,
            weflow_team: this.form.weflow_team,
            active: this.form.active,
            phone: this.form.phone,
            email: this.form.email,
            gender: this.form.gender,
            user_type: this.form.user_type,
            mensagem: this.form.message,
          },
        });
        if (resp.data) {
          this.loading = false;
          this.successMsg = true;
        } else {
          this.loading = false;
        }
        console.log(resp);
      }
    },
  },
  data: () => ({
    formValid: true,
    loading: false,
    successMsg: false,
    passwordType: "password",
    validations: {},
    userTypes: [
      {id: "teacher", title: "Professor"},
       {id: "student", title: "Aluno"},
        {id: "admin", title: "Administrador"}
    ],
    form: {
      email: "",
      city: "",
      user_type: "",
      password: "",
      name: "",
      birthdate: "",
      active: false,
      weflow_team: false,
      phone: "",
    },
  }),
};
</script>

<style scoped>
.logo-form {
  width: 100px;
  margin-left: 35%;
  margin-top: 20px;
  height: 100px;
}
</style>