<template>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="/assets/logo_white.png"
          transition="scale-transition"
          width="40"
        />
      </div>

        <v-app-bar-nav-icon @click="click"></v-app-bar-nav-icon>


      <v-spacer></v-spacer>
      <div v-if='currentUser'>
        <v-btn
          @click="$router.push(`editUser/${currentUser.id}`)"
          text
        >
            <span class="mr-2">{{currentUser.email}}</span>
            <v-icon>mdi-account</v-icon>
        </v-btn>
      </div>
      <div v-else>
          <v-btn
            href="/login"
            text
          >
            <span class="mr-2">Login</span>
            <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </div>
      
    </v-app-bar>
</template>

<script>
  import { mapGetters } from "vuex";
  //import Drawer from './Drawer'; 
    export default {
     
      computed: {
        currentUser(){
         return localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : this.userDefault
        }
      },
      data: () => ({
        group: null,
        userDefault: {
          id: null,
          username: '',
          email: '',
          role: '',
          plan: {},
          token: '',
        }
      }),
      methods: {
        ...mapGetters("User", {
          users: 'GET_USER'
        }),
        click(){
          this.$emit('click');
        },
      },
      props: {
        changeDrawer: ()=>{},
                drawer: {}

      }
    }
</script>