<template>
  <v-container>
    <v-card :loading="loading" class="mx-auto my-12" max-width="374">
      <v-card-text>
          <v-card>
            <v-card-title>
              Thumbnail
            </v-card-title>
            <v-card-image >
              <img :src="image" width="100%"/>
            </v-card-image>
            <v-card-text>
              <UploadCategoryComponent fieldname="class_category[thumbnail]" />
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-title>
              Imagem Nível 1
            </v-card-title>
            <v-card-image >
              <img :src="level1" width="100%"/>
            </v-card-image>
            <v-card-text>
              <UploadCategoryComponent fieldname="class_category[level_1]" />
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-title>
              Imagem Nível 2
            </v-card-title>
            <v-card-image >
              <img :src="level2" width="100%"/>
            </v-card-image>
            <v-card-text>
              <UploadCategoryComponent fieldname="class_category[level_2]" />
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-title>
              Imagem Nível 3
            </v-card-title>
            <v-card-image >
              <img :src="level3" width="100%"/>
            </v-card-image>
            <v-card-text>
              <UploadCategoryComponent fieldname="class_category[level_3]" />
            </v-card-text>
          </v-card>
       
      </v-card-text>
    </v-card>
  </v-container>
</template>


<script>
  import UploadCategoryComponent from '@/components/UploadCategoryComponent';

  export default {
      components: {
        UploadCategoryComponent
      },
      data(){
        return {
          loading: false,
          image: "", 
          level1: "",
          level2: "",
          level3: ""
        }
      },
      mounted(){
        this.retrieveCategory();
      },
      methods: {
        async retrieveCategory(){
          this.loading = true;
          const resp = await this.$api.get(`/class_categories/${this.$route.params.id}`);
          console.log(resp.data);
          if(resp.status == 200){
              this.image = resp.data.image
              this.level1 = resp.data.level_1,
              this.level2 = resp.data.level_2,
              this.level3 = resp.data.level_3
          } else {
              alert("erro")
          }
          this.loading = false;

        },
      }
  }
</script>