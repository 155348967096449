<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
  <template  v-slot:activator="{ on, attrs }">
    <v-icon
      small
      v-bind="attrs"
      v-on="on"
    > mdi-antenna
    </v-icon>
  </template>
  <v-card>
    <v-card-title class="headline black lighten-2" style="color: #52BD33">
      Notificação
    </v-card-title>

    <v-card-text style="margin-top: 10px">
     Deseja enviar uma notificação dessa aula para todos os alunos? <br> (Aviso de nova aula)
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn style="color: grey; font-size: 15px; font-weight: bold" text @click="dialog = false">
        Cancelar
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn style="color: #52BD33; font-size: 15px; font-weight: bold" text @click="click()">
        Confirmar
      </v-btn>
    </v-card-actions>
  </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    item: {}
  },
methods: {
  click(){
    this.$emit("dialog-response", { item: this.item, response: 'data'});
    this.dialog = false;
  }
},
data(){
  return{
    dialog: false
  }
}
}
</script>