<template>

    <div>
        <Layout>
          <template #content>

            <div class="title-box">
                <h2>Em qual plano deseja aplicar seu cupom?</h2>
            </div>
            <div v-if="card.length > 0" class="containerManageSubscription">
                <Card :coupon="true" :onclick="teste" :isPopulate="true" :plansObj="i" v-for="i in card" :key="i" />
                <!-- <Card :isPopulate="false" :changePlan="true"/> -->
            </div>

          </template>
        </Layout>
    
      </div>
</template>
<script>

import { mapActions } from 'vuex';
import Layout from '@/layouts/HomeLayout.vue';
import Card from '@/components/Layout/CardSubscription/CardSubscription.vue'
import router from '../router/index'


export default {
    components: {
        Layout,
        Card
    },
    data() {
        return {
            card: []
        }
    },
    computed: {

    },
    methods: {
        ...mapActions("Plan", ["REQUEST_PLANS"]),

        async getList() {
            // const resp = await this.$api.get('/all_plans')
            const resp = await this.$api.get('/payment_plans')
            // console.log(resp);
            this.card = resp.data;
        },
        getPlan(i) {
            router.push(`/createAccount-v2/${i}`)
        },
        toFreePlan() {
            console.log('To free plan');
            router.push(`/createAccount-v2/0`);
        },
        teste() {
            console.log(`teste`)
        }

    },
    created() {
        this.getList()
    },

}
</script>
<style>
*,
p,
h2,
h3,
h4 {
    font-family: "Roboto", sans-serif !important;
}



.containerCreate-form {
    background-color: #f8f8f8;
    height: 100%;
}

.title-box {
    margin: 1rem 2rem;

    display: flex;
    justify-content: center;
    background-color: black;
    border-radius: 15px;
}

.title-box h2 {
    padding: 20px;
    color: #4eba46;
    font-size: 32px;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerManageSubscription {
    /*padding: 40px 0 40px 35px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;*/
    display: grid;
    gap: 1rem;
    padding: 40px 60px 40px 60px;
    margin-inline: 40px;
}

@media (min-width: 600px) {
    .containerManageSubscription {
        margin-inline: 0 !important;
        grid-template-columns: repeat(2, 1fr);
        padding: 0;
    }
}

/* Screen larger than 900px? 3 columns */
@media (min-width: 900px) {
    .containerManageSubscription {
        grid-template-columns: repeat(3, 1fr);
        padding: 0 !important;
        margin-inline: 0 !important;
    }
}

@media (min-width: 1200px) {
    .containerManageSubscription {
        grid-template-columns: repeat(3, 1fr);
        padding: 20px 20px 20px 20px !important;
        margin-inline: 40px !important;
    }
}


@media (max-width: 1023px) {
    .containerManageSubscription {
        justify-content: center;
        margin: 10px 0;
        padding: 10px;
    }
}
</style>