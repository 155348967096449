<template>
    <ListCoupons />
</template>


<script>
import ListCoupons from "@/components/Coupons/ListCoupons";
export default {
    components: {
        ListCoupons
    }
}
</script>