<template>
    <EditPlan />
</template>


<script>
  import EditPlan from "@/components/Plans/EditPlan";
  export default {
      components: {
          EditPlan
      }
  }
</script>