<template>
    <AddMediaContent />
</template>


<script>
  import AddMediaContent from "@/components/ClassItem/addMediaContent.vue"
  export default {
      components: {
        AddMediaContent
      }
  }
</script>