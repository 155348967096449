<template>
  <div class="containerAccount-profile">
    <div class="containerAccount-profileImg">
      <img width="124px" height="124px" :src="User.avatar" alt="">
    </div>
    <div class="containerAccount-profileInfo d-flex">
      <h3 class="containerAccount-profileInfoName">{{ User.first_name }}</h3>
      <span class="containerAccount-profileInfoEmail">{{ User.email }}</span>
      <span class="containerAccount-profileInfoAge">{{ User.birthdate }}</span>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      User: {}
    };
  },
  created() {
    const user = localStorage.getItem('user');
    this.User = JSON.parse(user);
  }
};
</script>

<style scoped>
.containerAccount-profile {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 45px;
}

.containerAccount-profileInfo {
  flex-direction: column;
}

.containerAccount-profileInfoName {
  font-size: 26px;
  font-weight: bold;
  color: #131313;
  font-family: "Roboto", sans-serif;
  margin-bottom: 5px;
}

.containerAccount-profileInfoEmail,
.containerAccount-profileInfoAge {
  font-size: 16px;
  font-weight: bold;
  color: #131313;
  opacity: 70%;
}
.containerAccount-profileImg img{
  border-radius: 50%;
}
</style>