<template>
    <v-container>
        <v-card>
            <v-card-title>
                Histórico de Pagamentos
            </v-card-title>
            
            <v-card-text>
              <v-alert type="success" v-if="successCancel">
                Pagamento cancelado com sucesso
              </v-alert>
              <v-btn class="btn-padding" color="success" @click="$router.push(`/editUser/${$route.params.id}/addPayment`)" >
                Adicionar Pagamento
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
             <v-dialog
                v-model="dialog"
                width="500"
              >
                <template  v-slot:activator="{ on, attrs }">
                  <v-btn 
                    v-bind="attrs"
                    v-on="on"
                    class="btn-padding" 
                    :loading="loadingCancel" 
                    color="error" 
                    @click="dialog = true" >
                      Cancelar Pagamento
                    <v-icon v-if="loadingCancel">
                      mdi-loading
                    </v-icon>
                    <v-icon v-else>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="headline grey lighten-2">
                    Cancelar Pagamento
                  </v-card-title>

                  <v-card-text>
                  Você tem certeza que deseja cancelar o pagamento desse usuário?
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn
                      color="primary"
                      text
                      @click="dialog = false"

                    >
                      Voltar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="cancelPayment()"

                    >
                      Confirmar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-list dense>
                <v-subheader>Pagamentos</v-subheader>
                <v-list-item-group
                  v-model="selectedItem"
                  color="primary"
                >
                  <v-list-item
                    v-for="(payment, i) in payments"
                    :key="i"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="payment.id"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-title v-text="payment.created_at"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-title v-text="payment.status"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-title>

                        {{payment.payment_plan_title}}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-title>

                        Expira em: {{payment.expires_in}}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon v-if="payment.status === 'approved' ">
                        mdi-check
                      </v-icon>
                      <v-icon v-else>
                        mdi-close
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>

        </v-card>
    </v-container>
</template>

<script>
export default {
    data: () => ({
      payments: [],
      dialog: false,
      loadingCancel: false,
      successCancel: false,
      loading: false,
      error: false,
    }),
    methods: {
        async cancelPayment()  {
          this.dialog = false;
          let paymentLength = this.payments.length;
          console.log(paymentLength)
          console.log(this.payments)
          let lastId = this.payments[paymentLength-1].id
          this.loadingCancel = true;
          try {
            const resp = await this.$api.post(`/users/${this.$route.params.id}/cancel_payment`, {
              user_id: this.$route.params.id
            })
            this.successCancel = true; 
            this.payments.push({
              id: lastId+1,
              status: "not_payed",
              payment_plan_title: "Cancelar Plano"

            })
            console.log("pagamentos",resp.data)
          }catch(err){
            this.error = true;
          }finally{
            setTimeout(() => {
              this.successCancel = false;
            },3000)
            this.loadingCancel = false;
          }
        },
        async retrievePayments()  {
          this.loading = true;
          try {
            const resp = await this.$api.get(`/users/${this.$route.params.id}/payments`)
            this.payments = resp.data; 
            console.log("pagamentos",resp.data)
          }catch(err){
            this.error = true;
          }finally{
            this.loading = false;
          }
        }
    },
    mounted(){ 
        this.retrievePayments();
    }
}
</script>

<style  scoped>
  .btn-padding{
    margin-right: 5px;
  }
</style>