<template>
  <v-container>
    <v-card :loading="loading" class="mx-auto my-12" max-width="374">
      <v-card-text>
          <v-card>
            <v-card-title>
              Thumbnail
            </v-card-title>
            <v-card-image >
              <img :src="currentClassItem.image" width="50%"/>
            </v-card-image>
            <v-card-text>
              <UploadComponent fieldname="class_item[thumbnail]" />
            </v-card-text>
          </v-card>

        <v-card>
          <v-card-title>Vídeo</v-card-title>
           <v-card-image v-if="currentClassItem.video != ''">
              <video width="100%" height="90%" controls :src="currentClassItem.video" />
            </v-card-image>
          <v-card-text>
           
            <AwsDirectUpload fieldName="video" />
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-title>Gráficos</v-card-title>
           <v-card-image v-if="currentClassItem.video != ''">
              <video width="100%" height="90%" controls :src="currentClassItem.graphic" />
            </v-card-image>
          <v-card-text>
           
            <AwsDirectUpload fieldName="graphic" />
          </v-card-text>
        </v-card>
       
      </v-card-text>
    </v-card>
  </v-container>
</template>


<script>
  import UploadComponent from '@/components/UploadComponent';
  import AwsDirectUpload from '@/components/AwsDirectUpload';
  import { getClassItem } from '@/services/classItemService';

  export default {
      components: {
        UploadComponent,
        AwsDirectUpload
      },
      data(){
        return {
          loading: false,
          currentClassItem: {
            image: "",
            video: ""
          }
        }
      },
      async mounted(){
        this.loading = true;
        const resp = await this.retrieveClassItem();
        this.loading = false;
        if(resp.data){
          this.currentClassItem = resp.data
        }
      },
      methods: {
        async retrieveClassItem(){
          return getClassItem(this.$route.params.id);
        }
      }
  }
</script>