// import axios from "axios";
import Vue from 'vue'

function getDashData() {
  return Vue.prototype.$api.get('/user_home').then((x) => x.data);
}

// async function getDashData() {
//   Vue.prototype.$api.get("/users");
//   console.log('chegou na service');
// }

export { getDashData };
