<template>
  <div>
    <Layout>
      <template #content>
        <div class="containerContent-coupon">
          <h1 class="containerContent-coupon-title">
            Resgatar cupom de desconto
          </h1>
          <p class="containerContent-coupon-desc">
            <img src="@/assets/check.svg" alt=""> Insira o código do seu cupom no campo abaixo
          </p>
          <div class="containerContent-coupon-contact">
            <v-form @submit.prevent="submitForm">
              <v-container>
                <v-row class="d-flex justify-center">
                  <v-col md="10" sm="12">
                    <v-label class="containerContent-coupon-contact-label">Inserir seu código</v-label>
                    <v-text-field
                      class="containerForm-input"
                      v-model="code"
                      :rules="[(v) => !!v || 'Código é obrigatório']"
                      hide-details="auto"
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row >
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn class="containerContent-coupon-contact-btn" type="submit">Resgatar</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </div>
        </div>
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from '@/layouts/HomeLayout.vue';

export default {
  components: {
    Layout
  }
};
</script>
<style scoped>
.containerContent-coupon {
  max-width: 730px;
  width: 100%;
  margin: 0 auto;
  height: calc(100% - 40px);
  background: #fff;
  border-radius: 8px;
  margin-top: 40px;
  padding: 30px;
}
.containerContent-coupon-title {
  font-size: 20px;
  padding: 0px;
  margin-bottom: 28px;
  font-weight: bold;
}
.containerContent-coupon-desc {
  font-size: 16px;
  margin-bottom: 18px;
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.containerContent-coupon-contact .v-label  {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
.containerContent-coupon-contact-btn {
  width: 150px;
  height: 50px!important;
  background: #CEF9C6!important;
  border-radius: 25px;
  box-shadow: none;
}

</style>
<style>
.containerContent-coupon-contact .v-input__slot {
  border-radius: 8px!important;
}
.containerContent-coupon-contact-btn span {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
}
</style>