<template>
  <v-container>
    <v-card :loading="loading" class="mx-auto my-12" max-width="374">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-img
        height="100"
        class="logo-form"
        :src="require('../assets/logo_green.png')"
      ></v-img>

      <v-form @submit.prevent="submit" ref="form">
        <v-card-text>
          <v-alert type="success" v-if="successMsg">
            Login realizado com sucesso!
          </v-alert>
          <v-row align="center" class="mx-0">
            <v-text-field
              v-model="form.email"
              label="Email"
              :rules="[(v) => !!v || 'Email é obrigatório']"
              hide-details="auto"
            ></v-text-field>

            <br />
          </v-row>
           <v-row align="center" class="mx-0">
            <v-text-field
              v-model="form.password"
              label="Senha"
              type="password"
              :rules="[(v) => !!v || 'Email é obrigatório']"
              hide-details="auto"
            ></v-text-field>
           </v-row>
        </v-card-text>

        <v-card-text>
          <v-btn block type="submit" color="primary">
            <span v-if="loading"> Carregando </span>
            <span v-else> Enviar </span>
          </v-btn><br />
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>


<script>
// import axios from "axios";
import { mapActions } from "vuex";

export default {
  title: 'Login',
  methods: {
    ...mapActions("User", ["LOGIN_USER"]),
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const resp  = await this.LOGIN_USER(this.form)
        if (resp.data) {
          this.loading = false;
          this.successMsg = true;
        } else {
          this.loading = false;
        }
        console.log(resp);
      }
    },
  },
  data: () => ({
    formValid: true,
    loading: false,
    successMsg: false,
    validations: {},
    form: {
      email: "",
      password: ""
    },
  }),
};
</script>

<style scoped>
.logo-form {
  width: 100px;
  margin-left: 35%;
  margin-top: 20px;
  height: 100px;
}
</style>