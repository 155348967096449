<template>
    <ListPayments />
</template>

<script>
import ListPayments from '@/components/Users/ListPayments.vue';

export default {
    components: {
        ListPayments
    }
}
</script>