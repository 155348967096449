<template>
    <ListUsers />
</template>
<script>
import ListUsers from '@/components/ListUsers';
export default {
    components: {
        ListUsers,
    }
}
</script>