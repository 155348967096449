<template>
  <v-container>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Cancelar Pagamento
        </v-card-title>

        <v-card-text>
          Você tem certeza que deseja deletar esse comentário?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="dialog = false"> Voltar </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteComment()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="items.length <= 0">
      <h1>Aula ainda não possui comentários</h1>
    </div>
    <div v-else>
      <v-list three-line>
        <v-subheader v-if="header" v-text="header"></v-subheader>
        <template v-for="item in items">
          <v-list-item :key="item.user_name">
            <v-list-item-avatar>
              <v-img :src="item.user_avatar"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="item.user_name"></v-list-item-title>
              <v-list-item-subtitle v-html="item.text"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                icon
                color="error"
                v-bind="attrs"
                v-on="on"
                @click="openDialog(item.id)"
              >
                <v-icon v-if="loadingDelete"> mdi-loading </v-icon>
                <v-icon v-else> mdi-close </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    loadingDelete: false,
    dialog: false,
    commentId: 0,
    header: "Comentários",
    items: [],
  }),
  mounted() {
    this.retrieveComments();
  },
  methods: {
    openDialog(commentId) {
      this.dialog = true;
      this.commentId = commentId;
    },
    async retrieveComments() {
      this.loading = true;
      try {
        const resp = await this.$api.get(
          `/class_items/${this.$route.params.id}/comments`
        );
        this.items = resp.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async deleteComment() {
      this.dialog = false;
      this.loadingDelete = true;
      try {
        const resp = await this.$api.delete(
          `/class_items/${this.$route.params.id}/comments/${this.commentId}`
        );
        if (resp.data) {
          this.items = this.items.filter((item) => item.id != this.commentId);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingDelete = false;
      }
    },
  },
};
</script>
