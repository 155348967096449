<template>
    <CreateCoupon />
</template>
  
  
<script>
import CreateCoupon from "@/components/Coupons/CreateCoupon";
export default {
    components: {
        CreateCoupon
    }
}
</script>