<template>
  <v-container>
    <v-card :loading="loading" class="mx-auto my-12" max-width="374">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-img
        height="100"
        class="logo-form"
        src="/assets/logo_green.png"
      ></v-img>

      <v-container>
        <h2>Recuperação de Senha</h2>
      </v-container>

      <v-form @submit.prevent="submit" ref="form">
        <v-card-text>
          <v-alert type="error" v-if="error">
            {{  errorMessage }}
          </v-alert>
          <v-alert type="success" v-if="successMsg">
            Sua senha foi alterada com sucesso!
          </v-alert>
          <v-row v-else align="center" class="mx-0">
            <v-text-field
              v-model="form.password"
              label="Nova Senha"
              type="password"
              :rules="[(v) => !!v || 'Senha é obrigatório']"
              hide-details="auto"
            ></v-text-field
            ><br />

            <v-text-field
              v-model="form.passwordConfirm"
              label="Repita a nova senha"
              type="password"
              :rules="[
                (v) => !!v || 'Repetição de senha é obrigatório',
                (v) => v === form.password || 'Senhas não são iguais',
              ]"
              hide-details="auto"
            ></v-text-field>
          </v-row>
        </v-card-text>

        <v-card-text v-if="!successMsg">
          <v-btn block type="submit" color="primary">
            <span v-if="loading"> Carregando </span>
            <span v-else> Salvar </span>
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>


<script>
// import axios from "axios";

export default {
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.error = false;
        let url = `/users/${this.$route.query.u}/recovery_password`;

        try {
          const resp = await this.$api.post(url, {
            password: this.form.password,
            recovery_token: this.$route.query.t,
            user_id: this.$route.query.u
          }); 
          if (resp.status === 200) {
            this.loading = false;
            this.successMsg = true;
          } else {
            this.error = true;
            this.loading = false;
          }
        } catch (err) {
          this.error = true;
        } finally {
          this.loading = false;
        }
      }
    },
  },
  data: () => ({
    formValid: true,
    loading: false,
    successMsg: false,
    errorMessage: "",
    error: false,
    validations: {},
    form: {
      password: "",
      passwordConfirm: "",
    },
  }),
};
</script>

<style scoped>
.logo-form {
  width: 100px;
  margin-left: 35%;
  margin-top: 20px;
  height: 100px;
}
</style>
