<template>
    <CodeTv></CodeTv>
</template>

<script>
import CodeTv from '../../components/Tv/CodeTv.vue';
export default{
    components: {CodeTv}
}
</script>

<style>

</style>