<template>
  <div class="bg">

    <transition name="modal" v-if="showModal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-body">
              <slot name="body">
                <h2 style="text-align: center; padding-top: 0;">Atenção!</h2>
                Insira esse código no seu dispositvo
              </slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="closeModal()">
                  OK
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
      <modal @close="showModal = false"></modal>

    </transition>
    <div class="box" v-if="!this.code.conected">
      <h2 class="branco">Transmitir para SMART TV</h2>
      <h2 class="branco">
        Digite o código abaixo no seu celular para dar início à transmissão
      </h2>
      <h1 class="branco code bold">{{ code.codetv }}</h1>

      <div class="img">
        <img src="../../assets/logo_green.png" width="100" />
      </div>
    </div>
    <div v-if="this.code.conected" style="display: flex; justify-content: center;">
      <video ref="video" width="100%" controls  autoplay="autoplay" @pause="pauseVideoDesktop"
        @play="playVideoDesktop">
        <source :src="this.code.urlVideo" type="video/mp4" />
      </video>
    </div>
    <!-- <button @click="Conect" style="background-color: aliceblue">
          Conectar
        </button> -->
    <!-- 
        <button @click="pauseVideo" style="background-color: aliceblue">Pausar</button>
        <br>
        <button @click="playVideo" style="background-color: aliceblue">Play</button> -->
</div>
</template>

<script>
import { db } from "../../main";

export default {
  created() {
    var randomCode = Math.floor(Math.random() * 90000) + 10000;
    const starCountRef = db.ref("code/" + randomCode);
    starCountRef.on('value', snapshot => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        console.log(data);
      } else {
        // atualize a página aqui
        location.reload();
      }
    });

    if (this.code.code) {
      console.log();
    } else {
      console.log();
    }

    this.code.codetv = randomCode;
    this.code.conected = false;
    this.code.status = "play";
    this.code.urlVideo =
      "";
    this.SaveCode(this.code.codetv);
    console.log(this.code.urlVideo);
  },
  data() {
    return {
      code: {
        codetv: null,
        conected: null,
        status: null,
        urlVideo: null,
      },
      showModal: true
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    playVideo() {
      this.$refs.video.play();
      console.log("play")
    },
    pauseVideo() {
      this.$refs.video.pause();
      console.log("pause")
    },

    playVideoDesktop() {
      db.ref("code/" + this.code.codetv)
      .set({
        codetv: this.code.codetv,
        conected: (this.code.conected = true),
        status: 'play',
        urlVideo: this.code.urlVideo,
      })
      .then(() => {
        console.log("conectado");
        console.log(this.code.status)
      })
      .catch((erro) => {
        console.log(erro);
      });

      console.log("play")
    },
    pauseVideoDesktop() {
      db.ref("code/" + this.code.codetv)
      .set({
        codetv: this.code.codetv,
        conected: (this.code.conected = true),
        status: 'pause',
        urlVideo: this.code.urlVideo,
      })
      .then(() => {
        console.log("conectado");
        console.log(this.code.status)
      })
      .catch((erro) => {
        console.log(erro);
      });

      console.log("pause")
    },

    SaveCode() {
      writeCodeData.bind(this)();
      this.DeleteCode = this.DeleteCode.bind(this);
      this.Conect = this.Conect.bind(this);
      function writeCodeData() {
        db.ref("code/" + this.code.codetv)
          .set({
            codetv: this.code.codetv,
            conected: this.code.conected,
            status: this.code.status,
            urlVideo: this.code.urlVideo,
          })
          .then(() => {
            // eslint-disable-next-line no-unused-vars
            var starCountRef = db.ref("code/" + this.code.codetv);
            starCountRef.on('value', (snapshot) => {
              const data = snapshot.val();
              this.code.conected = data.conected;
              this.code.status = data.status
              this.code.urlVideo = data.urlVideo
              if (this.code.status === "play") {
                this.playVideo()
                console.log("play");
              } else if (this.code.status === "pause") {
                this.pauseVideo()
                console.log("pause");
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
      writeCodeData();
    },

    Conect() {
      db.ref("code/" + this.code.codetv)
        .set({
          codetv: this.code.codetv,
          conected: (this.code.conected = true),
          status: this.code.status,
          urlVideo: this.code.urlVideo,
        })
        .then(() => {
          console.log("conectado");
          console.log(this.code.urlVideo)

        })
        .catch((erro) => {
          console.log(erro);
        });


    },

    DeleteCode() {
      db.ref("code/" + this.code.codetv)
        .remove()
        .then(() => {
          db.ref("code/" + this.code.codetv)
            .set({
              codetv: this.code.codetv,
              conected: (this.code.conected = false),
              status: this.code.status,
              urlVideo: this.code.urlVideo,
            })
          console.log("Remoção realizada com sucesso!");
        })
        .catch((error) => {
          console.log("Erro ao remover: " + error);
        });
    },
  },
};
</script>

<style>
.img {
  text-align: center;
  margin-top: 15%;
}

.box {
  border-top: 2px solid #6bb550;
  margin: auto;
  max-width: 70%;
  position: relative;
  top: 200px;
}

.branco {
  color: white;
  text-align: center;
}

.code {
  font-size: 60px;
}

.bold {
  font-weight: bold;
}

.bg {
  background-color: black;
  width: 100%;
  height: 100%;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  border-radius: 20px;
  padding: 20px 30px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-footer {
  display: flex;
  justify-content: center;
}

.modal-default-button {
  width: 121px;
  padding: 8px;
  border-radius: 27px;
  color: white;
  background: #5d96c8;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>