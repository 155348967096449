<template>
  <v-container class="containerLogin-form">
    <v-card :loading="loading" class="containerLogin-FormCard">
      <template slot="progress">
        <v-progress-linear color="#CEF9C6" height="10" indeterminate></v-progress-linear>
      </template>
      <div class="logo-form">
        <v-img height="116" width="116" :src="require('../assets/logo_green.png')"></v-img>
      </div>
      <v-alert type="error" v-if="error">
        E-mail ou senha incorretos!
      </v-alert>

      <v-form @submit.prevent="submit" ref="form" class="form">
        <v-container class="containerForm">
          <v-row class="containerForm-row">
            <v-col cols="12">
              <v-label class="containerForm-label">Email <span class="span-tiny-letters">(utilizado para o aplicativo e site)</span></v-label>
              <v-text-field class="containerForm-input" v-model="form.email"
                :rules="[(v) => !!v || 'Email é obrigatório']" hide-details="auto" solo></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-label class="containerForm-label">Senha <span class="span-tiny-letters">(utilizado para o aplicativo e site)</span></v-label>
              <v-text-field class="containerForm-input" v-model="form.password" :type="showPassword ? 'text' : 'password'"
                :rules="[(v) => !!v || 'Senha é obrigatório']" hide-details="auto" solo>
                <v-icon @click="togglePassword()" color="green" slot="append">
                  {{ showPassword ? 'mdi-eye' : 'mdi-eye-closed' }}
                </v-icon>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-end containerForm-row-link">
              <button type="button" @click="openModal()">
                <p>Esqueceu a senha?</p>
              </button>

              <Modal v-if="showModal" @closeModal="closeModal()">
                <div class="div-close">
                  <button class="" @click="closeModal">X</button>
                </div>

                <p class="text-warning">
                  insira seu e-mail para alterar a senha.
                </p>

                <v-form @submit.prevent="submitForgotPass" ref="forgotPassword">
                  <v-label class="containerForm-label">Email</v-label>
                  <v-text-field class="containerForm-input" v-model="forgotPassword.email"
                    :rules="[(v) => !!v || 'Email é obrigatório']" hide-details="auto" solo></v-text-field>

                  <v-btn block @click="submitForgotPass" color="containerForm-btn">
                    Enviar
                  </v-btn>
                </v-form>
              </Modal>
            </v-col>
          </v-row>
        </v-container>
        <v-row class="justify-center">
          <v-col sm="12" lg="7">
            <v-btn block type="submit" color="containerForm-btn">
              <template v-if="loading">
                Carregando
              </template>
              <template v-if="!loading">
                Entrar
              </template>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col sm="12">
            <span class="containerForm-row-link d-flex justify-center">
              Não tem uma conta?
              <router-link to="/choosePlan">Cadastre-se</router-link>
            </span>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-snackbar v-model="showSuccessToast" color="green" :timeout="3000">
      E-mail enviado com sucesso!

      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="showSuccessToast = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
// import axios from "axios";
import { mapActions } from "vuex";
import Modal from "@/components/shared/Modal";
// import ModalDelete from "@/components/shared/ModalDelete";
import router from '../router/index'

export default {
  components: {
    Modal,
  },
  title: "Login",
  methods: {
    ...mapActions("User", ["LOGIN_USER", "RECOVERY_PASSWORD"]),
    ...mapActions("Dashboard", ["REQUEST_DASHBOARD"]),
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        this.LOGIN_USER(this.form)
          .then(async () => {
            const dash_Resp = await this.REQUEST_DASHBOARD();
            localStorage.setItem("plan", JSON.stringify(dash_Resp.plan))

            router.push({ path: '/'});
            // if (resp.type == "admin") router.push({ path: "/painel" });
          })
          .catch(() => {
            this.error = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    async submitForgotPass() {

      if (this.$refs.forgotPassword.validate()) {
        this.RECOVERY_PASSWORD(this.forgotPassword)
          .then()
          .catch(() => {
            this.error = true;
          })
          .finally(() => {
            this.showModal = false;
            this.showSuccessToast = true;
          });
      }
    },

    openModal() {

      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
  data: () => ({
    formValid: true,
    loading: false,
    error: false,
    showSuccessToast: false,
    successMsg: false,
    showModal: false,
    validations: {},
    forgotPassword: {
      email: "",
    },
    showPassword: false,
    form: {
      email: "",
      password: "",
    },
  }),
  mounted() {
    localStorage.clear();
  }
};
</script>

<style scoped>
.span-tiny-letters {
  font-size: 10px;
  color: green;
}
.logo-form {
  padding-bottom: 35px;
  margin-bottom: 35px;
  border-bottom: 1px solid #ececec;
}

.logo-form>div {
  margin: 0 auto;
}

.text-warning {
  text-align: center;
  font-weight: bold;
}

.containerLogin-FormCard {
  padding: 50px 30px 20px;
  position: relative;
}

.containerForm,
.col {
  padding: 0px;
}

.containerForm .v-label {
  font-size: 16px;
  color: #131313;
  font-weight: bold;
  margin-bottom: 3px;
}

.containerForm-row {
  gap: 35px;
}

.containerForm-row-link {
  margin: 15px 0 25px;
  font-size: 14px;
  color: #1d201c;
  font-weight: bold;
  gap: 5px;
}

.containerForm-row-link a {
  color: #1d201c;
}

.div-close {
  display: flex;
  justify-content: flex-end;
}

.containerForm-btn {
  min-height: 50px;
  background-color: #cef9c6 !important;
  border-radius: 25px;
  box-shadow: none;
  margin-bottom: 55px;
}

@media (max-width: 767px) {
  .containerForm {
    padding-top: 15px;
  }

  .containerForm-row {
    gap: 20px;
  }

  .logo-form {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .containerLogin-FormCard {
    padding-top: 25px;
  }

  .containerForm-row-link {
    margin: 10px 0 10px;
  }

  .containerForm-btn {
    margin-top: 10px;
    margin-bottom: 25px;
  }
}
</style>
<style>
.containerForm-input .v-input__slot {
  border-radius: 8px;
  box-shadow: none !important;
  background: #eaeaea !important;
}

.containerForm-input .v-input__slot input {
  font-size: 16px;
  color: #131313;
  font-weight: bold;
}

.containerForm-btn .v-btn__content {
  font-size: 14px;
  color: #1d201c;
  font-weight: bold;
  text-transform: capitalize;
}

.containerLogin-FormCard .v-card__progress {
  position: absolute;
}
</style>
