<template>
  <div class="containerHeader-user">
    <v-avatar size="48">
      <img :src="user.avatarUrl" alt="User Avatar">
    </v-avatar>
    <div class="containerHeader-userName">{{ user.name }}</div>
    <button class="containerHeader-userBtn desktop" @click="toggleInfo">
      <img src="@/assets/user_icon_homeV2.png">
    </button>
    <div class="containerHeader-userInfo desktop" v-if="visible">
      <!-- <router-link to="">
        Conta
      </router-link> -->
      <!-- <router-link to="login-user"> -->
        <button @click="logout">
          Sair
        </button>
      <!-- </router-link> -->
    </div>
  </div>
</template>

<script>
  import {mapActions} from 'vuex';
export default {
  data() {
    return {
      user: {
        name: 'Bem-vindo',
        avatarUrl: require('@/assets/user_photo_homeV2.png')
      },
      visible: false
    }
  },
  methods: {
    ...mapActions("User", ["LOGOUTV2"]),
    logout(){
      this.LOGOUTV2()
    },
    toggleInfo() {
      this.visible = !this.visible;
    },
    closeInfo(event) {
      if (!event.target.closest('.containerHeader-user')) {
        this.visible = false;
      }
    }
  },
  mounted() {
    document.body.addEventListener('click', this.closeInfo);
  },
  beforeUnmount() {
    document.body.removeEventListener('click', this.closeInfo);
  },

  created() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.user.name = user.first_name;
    this.user.avatarUrl = user.avatar;
  }
}
</script>
<style scoped>
.containerHeader-user {
  max-width: 220px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
}

.containerHeader-userName {
  font-size: 14px;
  color: #fff;
  font-weight: bold;
}

.containerHeader-userInfo {
  position: absolute;
  background: #FFFFFF;
  border-radius: 5px;
  width: 100px;
  /*height: 55px; */
  right: 0;
  top: 100%;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  z-index: 10;
}

.containerHeader-userInfo::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid #fff;
  position: absolute;
  right: 3px;
  top: -8px;
  z-index: 5;
}

.containerHeader-userInfo a {
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  text-decoration: none;
}
</style>