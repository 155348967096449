<template>
    <CreateClassItem />
</template>
<script>
import CreateClassItem from '@/components/CreateClassItem';

export default {
    components: {
      CreateClassItem
    }
}
</script>