<template>
    <div class="containerLogin">

        <v-container class="containerLogin-form">
            <v-card class="containerLogin-FormCard">

                <v-container class="containerForm">
                    <h1 style="padding-top: 0;">Parabens!</h1>
                    <p>Você acaba de adquirir um plano Weflow!</p>
                    <p>Clique no botão abaixo para prosseguir! </p>
                </v-container>
                <v-row class="justify-center">
                    <v-col sm="12" lg="7">
                        <v-btn block type="button" @click="goToLogin" color="containerForm-btn">
                            Prosseguir
                        </v-btn>
                    </v-col>
                </v-row>

            </v-card>

        </v-container>
    </div>

</template>
<script>

import router from '../router/index';

export default {
    components: {

    },
    data() {
        return {
        }
    },
    computed: {

    },
    methods: {
        goToLogin() {
            router.push(`/login-user`);
        }
    }
}
</script>
<style scoped>
.containerLogin {
    background: #000000;
    height: 100vh;
}

.containerLogin .containerLogin-form {
    height: 100%;
    display: grid;
    place-items: center;
}


.containerLogin-FormCard {
    padding: 50px 30px 20px;
    position: relative;
}

.containerForm,
.col {
    padding: 0px;
}

.containerForm .v-label {
    font-size: 16px;
    color: #131313;
    font-weight: bold;
    margin-bottom: 3px;
}


.containerForm-btn {
    min-height: 50px;
    background-color: #cef9c6 !important;
    border-radius: 25px;
    box-shadow: none;
    margin-bottom: 55px;
}

@media (max-width: 767px) {
    .containerForm {
        padding-top: 15px;
    }

    .containerLogin-FormCard {
        padding-top: 25px;
    }

    .containerForm-btn {
        margin-top: 10px;
        margin-bottom: 25px;
    }
}


.containerForm-btn .v-btn__content {
    font-size: 14px;
    color: #1d201c;
    font-weight: bold;
    text-transform: capitalize;
}
</style>