<template>
    <CreateUserForm/>
</template>

<script>
import CreateUserForm from '@/components/CreateUserForm';
export default {
    components: {
        CreateUserForm
    }
} 
</script>