<template>
  <v-container>
    <v-card :loading="loading" class="mx-auto my-12" max-width="374">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-form @submit.prevent="submit" ref="form">
        <v-card-text>
          <v-alert type="success" v-if="successMsg">
           Usuário atualizado com sucesso!
          </v-alert>
          <v-row align="center" class="mx-0">
            <v-text-field
              v-model="form.name"
              label="Nome"
              :rules="[(v) => !!v || 'Nome é obrigatório']"
              hide-details="auto"
            ></v-text-field><br />
           
            <v-text-field
              v-model="form.email"
              label="Email"
              :rules="[(v) => !!v || 'Email é obrigatório']"
              hide-details="auto"
            ></v-text-field>
            <br />
           
            <v-text-field
              v-model="form.phone"
              :rules="[(v) => !!v || 'Telefone é obrigatório']"
              v-mask="['(##)#####-####']"
              label="Telefone"
              hide-details="auto"
            ></v-text-field
            ><br />
            <v-radio-group
              :rules="[(v) => !!v || 'Selecione uma categoria']"
              v-model="form.gender"
            >
              <v-radio :label="`Masculino`" value="male"></v-radio>
              <v-radio
                :label="`Feminino`"
                value="female"
              ></v-radio> </v-radio-group
            ><br />
            <v-checkbox
              v-model="form.weflow_team"
              :label="`Time WeFlow`"
            ></v-checkbox><br />

            <v-checkbox
              v-model="form.active"
              :label="`Usuário ativo`"
            ></v-checkbox>

            <v-select
              v-model="form.user_type"
              :items="userTypes"
              item-text="title"
              item-value="id"
              label="Selecione o tipo do usuário"
              outlined
              dense
            ></v-select>
            <v-container>
              <v-btn type="success" @click="$router.push(`/editUser/${$route.params.id}/changePassword`)">
                <v-icon>
                  mdi-lock
                </v-icon>
                Alterar Senha
              </v-btn>
            </v-container>
            <v-container>
              <v-btn color="success" @click="$router.push(`/editUser/${$route.params.id}/listPayments`)">
                <v-icon>
                  mdi-cash
                </v-icon>
                Pagamentos
            </v-btn><br />
            </v-container>
            <v-text-field
              v-if="changePassword"
              v-model="form.password"
              label="Senha"
              type="password"
              :rules="[(v) => !!v || 'Senha é obrigatória']"
              hide-details="auto"
            ></v-text-field>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-btn block type="submit" color="primary">
            <span v-if="loading"> Carregando </span>
            <span v-else> Enviar </span>
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>


<script>
import { mapActions } from "vuex";

export default {
  mounted() {
     this.getUsers();
  },
  methods: {
    ...mapActions("User", ["LOGIN_USER"]),
    async getUsers(){
         let url =
          `/users/${this.$route.params.id}`;

        const resp = await this.$api.get(url);
        if(resp.data){
            this.form.email = resp.data.email;
            this.form.name = resp.data.first_name;
            this.form.phone = resp.data.phone;
            this.form.gender = resp.data.gender;
            this.form.user_type = resp.data.type
            this.form.active = resp.data.active;
            this.form.weflow_team = resp.data.weflow_team;
            this.form.avatar = resp.data.avatar;
        }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let url =
          `/users/${this.$route.params.id}`;

        const resp = await this.$api.put(url, {
          user: {
            first_name: this.form.name,
            phone: this.form.phone,
            user_type: this.form.user_type,
            active: this.form.active,
            weflow_team: this.form.weflow_team,
            email: this.form.email,
            gender: this.form.gender,
          },
        });
        if (resp.data) {
          this.loading = false;
          this.successMsg = true;
        } else {
          this.loading = false;
        }
        console.log(resp);
      }
    },
  },
  data: () => ({
    formValid: true,
    loading: false,
    successMsg: false,
    changePassword: false,
    validations: {},
    userTypes: [
      {id: "teacher", title: "Professor"},
       {id: "student", title: "Aluno"},
        {id: "admin", title: "Administrador"}
    ],
    form: {
      email: "",
      city: "",
      password: "",
      avatar: "",
      name: "",
      active: false,
      weflow_team: false,
      user_type: "",
      message: "",
      phone: "",
    },
  }),
};
</script>

<style scoped>
.logo-form {
  width: 100px;
  margin-left: 35%;
  margin-top: 20px;
  height: 100px;
}
</style>