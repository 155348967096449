<template>
    <CreateCategory />
</template>


<script>
  import CreateCategory from "@/components/Categories/CreateCategory";
  export default {
      components: {
          CreateCategory
      }
  }
</script>