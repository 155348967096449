<template>
    <v-container >
        <v-row>
        <!-- <v-col>
            <v-card>
                <v-card-title>
                    Total Usuários
                </v-card-title>
                <v-card-text>
                    <b>
                        asd
                    </b>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col>
            <v-card>
                <v-card-title>
                    Total de Aulas
                </v-card-title>
                <v-card-text>
                    <b>asd</b>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col>
            <v-card>
                <v-card-text>
                    <b>asd</b>
                </v-card-text>
            </v-card>
        </v-col> -->
        </v-row>
    </v-container>
</template>

<script>
export default {
    
}
</script>

