function validateCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf === '') return false; 
  
    // Check CPF length
    if (cpf.length !== 11) return false;
  
    // Check for known invalid CPFs
    if (/^(\d)\1{10}$/.test(cpf)) return false;
  
    // Validate the first digit
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let remainder = 11 - (sum % 11);
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpf.charAt(9))) return false;
  
    // Validate the second digit
    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    remainder = 11 - (sum % 11);
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpf.charAt(10))) return false;
  
    return true;
}

function validateCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, ''); // Remove non-numeric characters
    if (cnpj === '') return false; // Empty CNPJ is invalid
  
    // Check CNPJ length
    if (cnpj.length !== 14) return false;
  
    // Check for known invalid CNPJs
    if (/^(\d)\1{13}$/.test(cnpj)) return false;
  
    // Validate the first digit
    let size = cnpj.length - 2;
    let numbers = cnpj.substring(0, size);
    let digits = cnpj.substring(size);
    let sum = 0;
    let pos = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += parseInt(numbers.charAt(size - i)) * pos--;
      if (pos < 2) pos = 9;
    }
    let remainder = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (remainder !== parseInt(digits.charAt(0))) return false;
  
    // Validate the second digit
    size = size + 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += parseInt(numbers.charAt(size - i)) * pos--;
      if (pos < 2) pos = 9;
    }
    remainder = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (remainder !== parseInt(digits.charAt(1))) return false;
  
    return true;
}

export { validateCNPJ, validateCPF}