<template>
  <v-container>
    <v-card :loading="loading" class="mx-auto my-12" max-width="374">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-card-title>
        Adicionar Pagamento
      </v-card-title>
      <v-form @submit.prevent="submit" ref="form">
        <v-card-text>
          <v-container v-if="successMsg">
             <v-alert type="success" >
              Pagamento atualizado com sucesso!
            </v-alert>
            <v-btn @click="$router.push(`/editUser/${$route.params.id}/listPayments`)">
              <v-icon>
                mdi-arrow-left-circle
              </v-icon>
              Voltar
            </v-btn>
          </v-container>
         
          <v-row v-else align="center" class="mx-0">
            <v-select
              v-model="payment_plan_id"
              :items="plans"
              item-text="title"
              item-value="id"
              label="Selecione o plano"
              outlined
              dense
            ></v-select>
          
          </v-row>
        </v-card-text>

        <v-card-text v-if="!successMsg">
          <v-btn block type="submit" color="primary">
            <span v-if="loading"> Carregando </span>
            <span v-else> Salvar </span>
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>


<script>

export default {
  methods: {
    async retrievePlans(){
      this.loading = true;

      try{
        const resp = await this.$api.get("/payment_plans")
        this.plans = resp.data;
      }catch(err){
        console.log(err)
      }finally{
        this.loading = false;
      }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let url =
          `/users/${this.$route.params.id}/receive_payment`;

        try{
          const resp = await this.$api.post(url, {
            payment_plan_id: this.payment_plan_id,
          });
          if (resp.status === 200) {
            this.loading = false;
            this.successMsg = true;
          } else {
            this.loading = false;
          }
        }catch(err){
          console.log(err)
        }finally{
          this.loading = false;
        }
      
      }
    },
  },
  mounted(){
    this.retrievePlans()
  },
  data: () => ({
    formValid: true,
    loading: false,
    successMsg: false,
    validations: {},
    plans: [],
    payment_plan_id: 0,
  }),
};
</script>

<style scoped>
.logo-form {
  width: 100px;
  margin-left: 35%;
  margin-top: 20px;
  height: 100px;
}
</style>