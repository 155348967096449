<template>
  <div>
    <Layout>
      <template #content>
        <div v-if="loaded" class="containerAccount">
          <div>
            <Profile />
            <!-- <Tasks :DashObj="Dashboard" /> -->
            <Actions />
          </div>
          <div v-if="!!Dashboard.plan && !Dashboard.plan.is_expired">
            <Card :account="true" :isPopulate="true" :plansObj="Dashboard.plan" />
            <!-- <Card :plansObj="Dashboard.plan" /> -->
          </div>
        </div>
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from '@/layouts/HomeLayout.vue';
import Profile from '@/components/Layout/Account/Profile.vue'
// import Tasks from '@/components/Layout/Account/Tasks.vue'
import Actions from '@/components/Layout/Account/Actions.vue'
import Card from '@/components/Layout/CardSubscription/CardSubscription.vue'
import { mapActions } from "vuex";


export default {
  components: {
    Layout,
    Profile,
    // Tasks,
    Actions,
    Card
  },
  created() {
    this.init()
  },
  methods: {
    ...mapActions("Dashboard", ["REQUEST_DASHBOARD"]),
    async init() {
      const resp = await this.REQUEST_DASHBOARD();
      this.Dashboard = resp
      this.loaded = true;
      const planToSet = !!this.Dashboard?.plan && !this.Dashboard.plan.is_expired ? JSON.stringify(this.Dashboard.plan) : null
      localStorage.setItem("plan", planToSet)
      console.log(this.Dashboard.plan);
    }

  },

  data() {
    return {
      Dashboard: {},
      loaded: false
    }
  },



}

</script>

<style scoped>
.containerAccount {
  background: #FFFFFF;
  padding: 35px;
  margin: 40px 30px 0;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1023px) {
  .containerAccount {
    flex-wrap: wrap;
    padding: 10px;
    margin: 10px;
  }

  .containerAccount-profile {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .containerAccount-tasks {
    flex-wrap: wrap;
    gap: 30px;
  }

}
</style>
<style>
@media (max-width: 1023px) {
  .containerAccount-actions {
    background: #fff;
  }

  .containerAccount-actionsAlternatives {
    justify-content: center;
  }
}
</style>import Dashboard from '../stores/Dashboard';import Dashboard from '../stores/Dashboard';

