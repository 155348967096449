<template>
    <div>
        <v-overlay :value="loader">
            <v-progress-circular indeterminate color="green" size="64"></v-progress-circular>
        </v-overlay>
        <Layout>
            <template #content>
                <div class="grid-content">
                    <div class="container-forms">
                        <h3>{{ plan.title }}</h3>

                        <v-card-text class="cardSubscription-price bgPrice">
                            <div class="text-center">
                                <div class="headlineAlone">
                                    R$ {{ plan.value.toFixed(2).replaceAll(".", ",") }}
                                </div>
                            </div>
                        </v-card-text>

                        <v-list class="cardSubscription-flat">
                            <v-list-item class="cardSubscription-flat-listItem  benefits">
                                <div class="icons" v-for="benefit in planBenefits" :key="benefit">
                                    <v-list-item-icon>
                                        <v-icon>mdi-check</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        {{ benefit }}
                                    </v-list-item-content>
                                </div>
                            </v-list-item>
                        </v-list>

                        <v-list class="cardSubscription-flat">
                            <v-list-item class="cardSubscription-flat-listItem">
                                <v-list-item-icon>
                                    <v-icon color="white">
                                        mdi-check
                                    </v-icon>
                                </v-list-item-icon>
                                <div class="">
                                    <v-list-item-content>Total: R$
                                    {{
                                        plan_installments > 0 
                                        ? '12x ' + (totalValue/12).toFixed(2) 
                                        : totalValue.toFixed(2).replaceAll(".", ",")
                                    }}                          
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <p class="payment-info">{{ plan_installments > 0 ? 'Pagamento parcelado' : 'Pagamento à vista' }}</p>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <span class="typePlan">Sem Cobrança</span>
                                    </v-list-item-content>
                                </div>
                            </v-list-item>
                        </v-list>

                        <v-list class="cardSubscription-flat" style="padding-bottom: 0;">
                            <v-list-item class="cardSubscription-flat-listItem benefits">
                                <div class="icons">
                                    <v-list-item-icon>
                                        <v-icon>mdi-ticket-percent</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        Adicionar cupom
                                    </v-list-item-content>
                                </div>

                                <div class="icons">
                                    <v-text-field @blur="searchCoupon()" class="containerForm-input" v-model="coupon"
                                        solo>
                                        <v-icon slot="append" style="cursor: pointer;" @click="searchCoupon"
                                            color="black">
                                            mdi-magnify
                                        </v-icon>
                                    </v-text-field>

                                    <div v-if="couponId" @click="clearCoupon"
                                        style="align-self: start; margin-top: 0.5rem; margin-left: 10px; cursor: pointer">
                                        <v-icon slot="append" color="red">
                                            mdi-close
                                        </v-icon>
                                    </div>
                                </div>
                                <div style="display: flex" v-if="!!couponData">
                                    <span class="typePlan"
                                        style="padding-inline: 10px; margin-right: 5px; font-weight: bolder">
                                        {{
            couponData.coupon_type == "discount_all"
                ? "Toda assinatura"
                : "Primeira cobrança"
        }}
                                    </span>
                                    <span class="typePlan" style="padding-inline: 10px; font-weight: bolder">
                                        - {{ couponData.value
                                        }}{{ couponData.discount_type == "percent" ? "%" : "R$" }}</span>
                                </div>
                            </v-list-item>
                        </v-list>

                        <div class="service">
                            <p>Suporte:</p>
                            <div class="icons">
                                <v-icon small>mdi-email</v-icon>
                                <span> suporte@weflowoficial.com </span>
                            </div>
                        </div>
                    </div>
                    <div class="container-forms">
                        <h3>Dados para cobrança</h3>

                        <div class="containerContent-coupon-contact">
                            <v-form @submit.prevent="submitForm" ref="form">
                                <div class="centerCreate">

                                    <v-label class="containerContent-coupon-contact-label">CPF/CPNJ</v-label>
                                    <v-text-field class="containerForm-input" v-mask="doc_mask"
                                        v-model="creditCardHolder.cpfCnpj" solo></v-text-field>

                                    <div class="tripleInput">
                                        <div class="input">
                                            <v-label class="containerContent-coupon-contact-label">CEP</v-label>
                                            <v-text-field class="containerForm-input" @input="fetchAddress"
                                                v-mask="['#####-###']" v-model="form.user.zip_code" solo></v-text-field>
                                        </div>
                                        <div class="input">
                                            <v-label class="containerContent-coupon-contact-label">Estado</v-label>
                                            <v-text-field class="containerForm-input" v-model="form.user.state"
                                                solo></v-text-field>
                                        </div>

                                        <div class="input">
                                            <v-label class="containerContent-coupon-contact-label">Cidade</v-label>
                                            <v-text-field class="containerForm-input" v-model="form.user.city"
                                                solo></v-text-field>
                                        </div>
                                    </div>

                                    <div class="tripleInput">
                                        <div class="input">
                                            <v-label class="containerContent-coupon-contact-label">Endereço</v-label>
                                            <v-text-field class="containerForm-input" :maxlength="150"
                                                v-model="form.user.address" solo></v-text-field>
                                        </div>

                                        <div class="input">
                                            <v-label class="containerContent-coupon-contact-label">Número</v-label>
                                            <v-text-field class="containerForm-input" v-model="form.user.number"
                                                solo></v-text-field>
                                        </div>

                                        <div class="input">
                                            <v-label class="containerContent-coupon-contact-label">Complemento</v-label>
                                            <v-text-field class="containerForm-input" v-model="form.user.complement"
                                                solo></v-text-field>
                                        </div>
                                    </div>

                                    <div v-if="plan.value > 0.00">
                                        <h3>Pagamento</h3>

                                        <div class="input" v-if="plan.permit_installments">
                                            <v-label class="containerContent-coupon-contact-label">Parcelas</v-label>
                                            <v-select class="containerForm-input" :disabled="true" :items="plan_installments"
                                                v-model="installments" solo></v-select>

                                        </div>
                                        <div class="input">
                                            <v-label class="containerContent-coupon-contact-label">Forma de
                                                pagamento</v-label>
                                            <v-select class="containerForm-input" v-model="paymentType" :disabled="plan_installments > 0"
                                                :items="paymentOptions" solo></v-select>
                                        </div>

                                        <div class="credit-card" v-if="paymentType === 'Cartão'">
                                            <div class="input">
                                                <v-label class="containerContent-coupon-contact-label">Nome do titular
                                                    do
                                                    cartão</v-label>
                                                <v-text-field v-model="creditCard.holderName"
                                                    class="containerForm-input" solo></v-text-field>
                                            </div>

                                            <div class="input">
                                                <v-label class="containerContent-coupon-contact-label">Número do
                                                    cartão</v-label>
                                                <v-text-field v-model="creditCard.number"
                                                    v-mask="['#### #### #### ####']" class="containerForm-input"
                                                    solo></v-text-field>
                                            </div>

                                            <div class="doubleInput">

                                                <div class="input">
                                                    <v-label class="containerContent-coupon-contact-label">Mês de
                                                        vencimento</v-label>
                                                    <v-text-field v-model="creditCard.expiryMonth"
                                                        class="containerForm-input" solo></v-text-field>
                                                </div>
                                                <div class="input">
                                                    <v-label class="containerContent-coupon-contact-label">Ano de
                                                        vencimento</v-label>
                                                    <v-text-field v-model="creditCard.expiryYear" persistent-hint
                                                        class="containerForm-input" :maxlength="4" hint="Ex: 2029"
                                                        solo></v-text-field>
                                                </div>
                                            </div>

                                            <div class="input">
                                                <v-label class="containerContent-coupon-contact-label">Código de
                                                    segurança</v-label>
                                                <v-text-field v-model="creditCard.ccv" :maxlength="3"
                                                    class="containerForm-input" solo></v-text-field>
                                            </div>
                                        </div>
                                        <div class="credit-card" v-if="paymentType === 'Boleto'">

                                            <p v-if="link.length == 0" class="bold"> O link de pagamento só é gerado
                                                após o
                                                clique em assinar.
                                            </p>
                                            <p v-if="link.length > 0" class="bold"> Clique
                                                <a style="color: #6CB856" target="_blank" :href="link">
                                                    aqui
                                                </a>
                                                para acessar seu link de pagamento.
                                            </p>

                                            <p class="bold">Atenção! Pode levar até 2 dias úteis para liberação</p>

                                        </div>
                                        <div class="credit-card" v-if="paymentType === 'PIX'">
                                            <p v-if="link.length == 0 && !checkIsCouponFull()" class="bold">
                                                O link de pagamento só é gerado
                                                após o
                                                clique em assinar.
                                            </p>
                                            <p v-if="link.length > 0" class="bold"> Clique
                                                <a style="color: #6CB856" target="_blank" :href="link">
                                                    aqui
                                                </a>
                                                para acessar seu link de pagamento.
                                            </p>

                                        </div>
                                    </div>

                                    <v-btn block :loading="loaderSubmit" :disabled="loaderSubmit" type="submit" color="containerForm-btn">
                                        Assinar
                                    </v-btn>

                                    <v-snackbar v-model="snackbar.show" :color="snackbar.type" :timeout="5000">
                                        {{ snackbar.message }}
                                        <div v-if="errors.length > 0">
                                            <p style="color: white" v-for="(error, index) in errors" :key="index"> - {{
            error }}</p>
                                        </div>

                                        <template v-slot:actions>
                                            <v-btn color="white" variant="text" @click="snackbar.show = false">
                                                Close
                                            </v-btn>
                                        </template>
                                    </v-snackbar>


                                    <v-dialog persistent v-model="showSuccessToast" width="500">
                                        <v-card class="v-card-home ">
                                            <v-card-text style="text-align: center;">
                                                <span class="span-card-home" style="color: green; font-weight: bold">
                                                    Assinatura realizada com sucesso! Faça o login novamente para
                                                    aplicar as
                                                    mudanças.</span>
                                                <br>
                                            </v-card-text>
                                            <v-card-actions class="footer-error-dialog">
                                                <v-btn class="btn-home-modal" color="green" @click="logout">Fazer login
                                                    novamente</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>

                                    <v-dialog persistent v-model="errorDialog.show" width="500">
                                        <v-card class="v-card-home ">
                                            <v-card-text class="v-card-text-error">
                                                <span class="span-card-home error-text"> {{ errorDialog.message
                                                    }}</span>
                                                <br>
                                                <div class="div-error-list" v-if="errorDialog.errors.length > 0">
                                                    <p style="color: black" v-for="(error, index) in errorDialog.errors"
                                                        :key="index"> - {{ error }}</p>
                                                </div>
                                            </v-card-text>
                                            <v-card-actions class="footer-error-dialog">
                                                <v-btn class="btn-home-modal" color="black"
                                                    @click="errorDialog.show = false">Ok</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>

                                </div>
                            </v-form>
                        </div>

                    </div>
                </div>
            </template>
        </Layout>
    </div>
</template>

<script>
import Layout from '@/layouts/HomeLayout.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        Layout
    },
    computed: {
        ...mapGetters('User', ['GET_USER']),
        doc_mask() {
            if (this.creditCardHolder.cpfCnpj.length <= 14) {
                return "###.###.###-##"; // CPF mask
            } else {
                return "##.###.###/####-##"; // CNPJ mask
            }
        },
    },
    data: () => ({
        ...mapGetters('User', ['GET_USER']),
        paymentType: null,
        link: '',
        showSuccessToast: false,
        errorDialog: {
            show: false,
            message: '',
            errors: []
        },
        snackbar: {
            type: 'success',
            message: '',
            show: false
        },
        coupon: "",
        couponId: null,
        couponData: null,
        loader: false,
        loaderSubmit: false,
        form: {
            user: {
                email: '',
                birthdate: '',
                active: true,
                weflow_team: false,
                user_type: 'student',
                one_signal_device_id: null,
                address: '',
                zip_code: '',
                complement: '',
                number: '',
                state: '',
                password: '',
                first_name: '',
                last_name: "",
                phone: '',
                city: '',
                gender: 'male'
            }
        },
        plan: {},
        plan_installments: [],
        paymentOptions: [
            'Cartão',
            'PIX'        
        ],
        installments: 1,
        creditCard: {
            holderName: "",
            number: "",
            expiryMonth: "",
            expiryYear: "",
            ccv: "",
        },
        creditCardHolder: {
            name: "",
            email: "",
            cpfCnpj: "",
            phone: "",
            postalCode: "",
            addressNumber: ""
        },
        confirmPassword: '',
        totalValue: 0.0,
        planBenefits: [],
        errors: [],
    }),
    methods: {
        ...mapActions("User", ["LOGOUT"]),
        logout() {
            this.LOGOUT()
        },
        checkIsCouponFull() {
            if (!this.couponData || (this.couponData.value != 100 && this.couponData.discount_type != 'percent')) {
                return false;
            }

            return true;
        },
        async getPlano() {
            const id = this.$route.params.idPlano;

            if (id != 0) {
                const resp = await this.$api.get(`/payment_plans/${id}`);

                this.totalValue = resp.data.value;
                this.plan = resp.data;


                const benefits = this.plan.benefits.split(";");
                this.planBenefits = benefits

                if (resp.data.permit_installments) {
                    for (let i = resp.data.min_installments; i <= resp.data.max_installments; i++) {
                        this.plan_installments.push(i);
                    }

                    this.paymentType = 'Cartão'

                    this.installments = resp.data.min_installments;
                    this.removePixOption()
                }

                return
            }

            this.plan = {
                id: 0,
                value: 0.00,
                title: "Sem assinatura",
                description: "Libera acesso por 1 mês",
                months: 1,
                user_type: "student",
                stores_id: "student_monthly_rev",
                benefits: "Mudar para a opção sem assinatura cancela seu plano atual",
                permit_installments: false,
                min_installments: 0,
                max_installments: 0
            }

            const benefits = this.plan.benefits.split(";");
            this.planBenefits = benefits
        },

        async benefits() {
            const id = this.$route.params.idPlano
            if (id == 0) {
                this.planBenefits = ["Mudar para a opção sem assinatura cancela seu plano atual"]
                return
            } else {
                this.planBenefits = await this.plan.benefits.split(";");
            }
        },
        async searchCoupon() {
            if (this.couponId != null && this.couponId != "") {
                this.callSnackbar('error', 'Cupom inválido ou inexistente.');
                return false;
            }

            const id = this.$route.params.idPlano;

            // 
            // if (!this.GET_USER().email) {
            //     this.callSnackbar('warning', 'Email.');
            //     this.clearCoupon();
            //     return;
            // }

            if (id != 0) {
                const resp = await this.$api.get(
                    `/check_coupon?email=${this.GET_USER().email
                    }&planId=${id}&description=${this.coupon.toUpperCase()}`
                );
                const data = resp.data;

                if (data.body.valid) {

                    this.couponId = data.body.id;
                    this.couponData = data.body;
                    this.applyCoupon(data.body.value, data.body.discount_type);
                } else {
                    this.callSnackbar('error', 'Cupom inválido ou inexistente.');
                    this.clearCoupon();
                }

            }
        },
        applyCoupon(couponVal, couponType) {

            switch (couponType) {
                case "value":
                    this.totalValue = this.totalValue - couponVal;
                    break;
                default:
                    this.totalValue =
                        this.totalValue - (couponVal / 100) * this.totalValue;
                    break;
            }

            this.callSnackbar('success', 'Cupom aplicado com sucesso!');
        },
        clearCoupon() {
            this.coupon = "";
            this.couponId = null;
            this.totalValue = this.plan.value;
            this.couponData = null;
        },
        async subscribeCreate() {
            if (this.$refs.form.validate()) {

                this.creditCard.number = this.creditCard.number.replace(/\s/g, '');

                const creditCardHolderObj = {
                    addressNumber: this.form.user.number,
                    cpfCnpj: this.creditCardHolder.cpfCnpj,
                    email: this.GET_USER().email,
                    name: this.creditCard.holderName,
                    phone: this.GET_USER().phone,
                    postalCode: this.form.user.zip_code
                }

                const object = {
                    user_id: this.GET_USER().id,
                    client: {
                        name: this.GET_USER().first_name,
                        email: this.GET_USER().email,
                        phone: this.GET_USER().phone,
                        mobilePhone: this.GET_USER().phone,
                        cpfCnpj: this.creditCardHolder.cpfCnpj,
                        postalCode: this.form.user.zip_code,
                        address: this.form.user.address,
                        addressNumber: this.form.user.number,
                        complement: this.form.user.complement,
                        province: this.form.user.state,
                        externalReference: this.form.user.externalReference,
                        notificationDisabled: true
                    },
                    payment_plan_id: this.$route.params.idPlano,
                    payment_object: {
                        billingType: this.getPaymentType(),
                        creditCard: this.creditCard,
                        creditCardHolderInfo: creditCardHolderObj,
                    },
                    coupon_id: this.couponId,
                }

                if (this.plan.permit_installments) {
                    object.payment_object.installmentCount = this.installments;
                }

                this.errors = [];

                this.$api.post('/users/payment_subscribe', object).then(() => {

                    if (this.checkIsCouponFull()) {
                        this.showSuccessToast = true;
                        return;
                    }

                    if (this.paymentType != 'Cartão') {
                        this.$api.get('/asaas/' + this.GET_USER().id + '/get_payment_link').then((resp) => {
                            this.link = resp.data.data;
                        });
                    } else {
                        this.showSuccessToast = true;
                    }


                }).catch((e) => {
                    this.errorDialog.show = true;
                    this.errorDialog.message = 'Algo deu errado. Confira os dados inseridos e tente novamente.';
                    this.errorDialog.errors = e.response.status == 500 ? [] : e.response.data.error;

                }).finally(() => {
                    this.loaderSubmit = false;
                })
            }
        },
        fetchAddress() {
            if (this.form.user.zip_code.length === 9) {
                // this.activateLoader();
                this.loader = true;
                // Make an API request to ViaCEP here
                const viaCEPUrl = `https://viacep.com.br/ws/${this.form.user.zip_code}/json/`;

                fetch(viaCEPUrl)
                    .then((response) => response.json())
                    .then((data) => {
                        // this.hideLoader();
                        this.loader = false;
                        this.form.user.state = data.uf;
                        this.form.user.city = data.localidade;
                        this.form.user.address = data.logradouro;

                        // this.address = data;
                    })
                    .catch((error) => {
                        this.loader = false;
                        this.hideLoader();
                        console.error(error);
                    });
            }
        },
        callSnackbar(type, message) {
            this.snackbar.type = type;
            this.snackbar.message = message;

            this.snackbar.show = true;
        },
        async submitForm() {
            if (this.getPaymentType() == 'CREDIT_CARD' && this.creditCard.expiryYear.length < 4) {
                alert('O ano de expiração deve ser extenso. Ex: 2029');
                return;
            }

            if (this.$refs.form.validate()) {
                this.loaderSubmit = true;

                this.subscribeCreate();
            }
        },

        getPaymentType() {
            switch (this.paymentType) {
                case 'PIX':
                    return 'PIX'
                case 'Boleto':
                    return 'BOLETO'
                case 'Cartão':
                    return 'CREDIT_CARD'
                default:
                    return 'CREDIT_CARD'
            }
        },
        removePixOption() {
            if (this.plan.permit_installments) {
                this.paymentOptions = this.paymentOptions.filter(f => f != 'PIX');
            }
        }
    },

    created() {
        this.getPlano();

    },
};
</script>

<style>
*,
p,
h2,
h3,
h4 {
    font-family: "Roboto", sans-serif !important;

}

.centerCreate {
    padding: 0 30px;
}

.containerCreate-form {
    background-color: #f8f8f8;
}

.service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.service .icons i.v-icon.v-icon {
    margin: 0 5px;
}

.v-card-text-error {
    text-align: center;
}

.error-text {
    color: red !important;
    font-weight: bold
}

.div-error-list {
    margin-top: 10px;
    text-align: justify
}

.footer-error-dialog {
    display: flex;
    justify-content: center
}

.v-card-home {
    padding-top: 24px;
}

.span-card-home {
    font-size: 18px;
    color: black;
}

.btn-home-modal {
    width: 50%;
    color: white !important;
}

.icons {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.flex {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    padding: 50px 20px;
}

.doubleInput {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.doubleInput .input {

    width: 49% !important;

}

.tripleInput {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.tripleInput .input {

    width: 33.3% !important;
    margin-right: 10px;

}

.card-plan {
    background-color: white;
    border-radius: 10px;
    padding: 25px;
    width: 30%;
    height: 85vh;
    margin: 0 10px;
}

.card-form {
    padding: 25px;
    width: 70%;
    height: 85vh;
    margin: 0 10px;
    background-color: white;
    border-radius: 10px;

}

.payment-info {
    color: #b1b1b1;
    font-size: 12px;
}


.cardSubscription-flat-listItem {
    padding: 0px;
    min-height: unset;
    background: #F7F6F3;
    border-radius: 10px;
    padding: 25px;
}

.cardSubscription-flat-listItem .typePlan {
    padding: 5px 0;
    background-color: #e2efff;
    color: #1876d0;
    font-size: 10px;
    text-align: center;
    border-radius: 10px;

}

.containerContent-coupon-contact {
    max-height: 100%;
    overflow: auto;
}

.containerContent-coupon-contact .v-label {
    font-size: 16px;
    font-weight: bold;
    color: #000;
}

.benefits {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.containerForm-input .v-input__slot {
    border-radius: 8px;
    box-shadow: none !important;
    background: #EAEAEA !important;
    margin-bottom: 0px !important;
    width: 100%;

}

.containerForm-btn {
    min-height: 50px;
    background-color: #CEF9C6 !important;
    border-radius: 25px;
    box-shadow: none;
    margin-bottom: 55px;
}

.cardSubscription-price {
    background: #FFF8DD;
    border-radius: 10px;
    padding: 18px 0;
    margin-bottom: 20px;
}

.bgPrice {
    background: #C5E9C3 !important;
}

.margin-top {
    margin: 20px 0;
}

.flex {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 10px;
}

.flex-print {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

}

.printBtn .v-btn {
    width: 40% !important;
    min-width: none;
}

.grid-content {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 0px;
    padding: 2rem;
}

@media screen and (max-width: 910px) {}

.container-forms {
    background-color: white;
    padding: 2rem;
    border-radius: 20px;
}
</style>

<style>
@media screen and (max-width: 910px) {
    .grid-content {
        grid-template-columns: 1fr;
        /* Change to 1 column */
        grid-template-rows: 80% 1fr;
        /* Change to 2 rows */
        grid-column-gap: 0px;
        /* No gap between columns */
        grid-row-gap: 10px;
        padding: 1rem;
        /* Adjust padding as needed */
    }

    .flex {
        flex-direction: column;
        align-items: center;
    }

    .card-form {
        width: 100%;
        margin-top: 10px;
    }

    .card-plan {
        width: 100%;
        margin-top: 10px;
    }

    .centerCreate {
        padding: 0;
    }
}
</style>