// import axios from "axios";
import Vue from "vue";

function getListCoupons() {
  return Vue.prototype.$api.get("/coupons").then((x) => x.data);
}

function getListReportCoupons(initial_date, final_date) {
  return Vue.prototype.$api
    .get(
      `/coupons_report?initial_date=${initial_date} 00:00:00&final_date=${final_date} 00:00:00`
    )
    .then((x) => x.data)
    .catch((err) => err);
}
function getUsers(initial_date, final_date, id) {
  return Vue.prototype.$api
    .get(
      `/coupons_report_users?initial_date=${initial_date} 00:00:00&final_date=${final_date} 00:00:00&coupon_id=${id}`
    )
    .then((x) => x.data)
    .catch((err) => err);
}

export { getListCoupons, getListReportCoupons, getUsers };
