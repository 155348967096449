import Vue from 'vue'
import Vuex from 'vuex'
import User from './User';
import Plan from './Plan';
import Payment from './Payment';
import Dashboard from './Dashboard';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showAlert: false,
    alertMessage: ''
  },
  mutations: {
    SET_ALERT(state, value) {
      state.showAlert = true
      state.alertMessage = value
    },
    TOGGLE_ALERT(state){
      state.showAlert = !state
    }

  },
  actions: {
  },
  modules: {
    User,
    Payment,
    Dashboard,
    Plan
  }
})
