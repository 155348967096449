<template>
      <v-dialog
        v-model="dialog"
        width="500"
      >
      <template  v-slot:activator="{ on, attrs }">
        <v-icon
          small
          v-bind="attrs"
          v-on="on"
        > mdi-delete
        </v-icon>
      </template>
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Deletar Item
        </v-card-title>

        <v-card-text>
         Você tem certeza que deseja deletar esse item?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="dialog = false"

          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="click()"

          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
</template>

<script>
export default {
    methods: {
      click(){
        this.$emit("click");
        this.dialog = false;
      }
    },
    data(){
      return{
        dialog: false
      }
    }
}
</script>