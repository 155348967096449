<template>
  <div class="containerAccount-actions">
    <h3 class="containerAccount-actionsName">Ações</h3>
    <div class="containerAccount-actionsAlternatives">
      <div class="containerAccount-actionsAlternatives-item">
        <img src="@/assets/tick.png" alt="">
        <router-link to="/account-v2-edit" class="containerAccount-actionsAlternatives-itemType">Editar
          perfil</router-link>
      </div>
      <div class="containerAccount-actionsAlternatives-item" v-if="currentUser.payment_type == 'web'">
        <img src="@/assets/tick.png" alt="">
        <router-link to="/manageSubscription-v2" class="containerAccount-actionsAlternatives-itemType">Mudar
          Plano</router-link>
      </div>
      <div class="containerAccount-actionsAlternatives-item" v-if="currentUser.payment_type == 'web'">
        <img src="@/assets/tick.png" alt="">
        <span class="containerAccount-actionsAlternatives-itemType">Cancelar plano atual</span>
      </div>
      <!-- <div class="containerAccount-actionsAlternatives-item">
        <img src="@/assets/tick.png" alt="">
        <router-link to="coupon-v2" class="containerAccount-actionsAlternatives-itemType">Cupom</router-link>
      </div> -->
    </div>
  </div>
</template>

<script>
  import { mapGetters} from 'vuex';

export default {
  data: () => ({
    ...mapGetters('User', ['GET_USER'])
  }),
  computed: {
    currentUser(){
      return this.GET_USER()
    },
  }

};
</script>
<style scoped>
.containerAccount-actionsName {
  font-size: 20px;
  font-weight: bold;
  color: #131313;
  font-family: "Roboto", sans-serif;

  margin-bottom: 5px;
}

.containerAccount-actionsAlternatives {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  max-width: 575px;
}

.containerAccount-actionsAlternatives-item {
  max-width: 270px;
  width: 100%;
  min-height: 90px;
  padding: 20px;
  background: #F7F6F3;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.containerAccount-actionsAlternatives-itemType {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  color: #131313;
  text-decoration: none;
}</style>