<template>
    <CreateSubcategory />
</template>


<script>
import CreateSubcategory from "../../components/Subcategories/CreateSubcategory.vue";
  export default {
      components: {
          CreateSubcategory
      }
  }
</script>