<template>
    <EditLevel />
</template>


<script>
  import EditLevel from "@/components/Levels/EditLevel";
  export default {
      components: {
          EditLevel
      }
  }
</script>