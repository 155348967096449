<template>
  <v-container>
    <v-btn color="success" @click="$router.push('/createCoupons')">
      Adicionar Cupom
      <v-icon>
        mdi-plus
      </v-icon>
    </v-btn>
    <v-alert v-if="message !== ''" type="success">
      {{ message }}
    </v-alert>
    <br />
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="coupons"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="$router.push(`/coupons/${item.id}`)">
          mdi-pencil
        </v-icon>
        <ModalDelete @click="deleteCoupon(item.id)" />
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import ModalDelete from "@/components/shared/ModalDelete";

import { getListCoupons } from "@/services/couponService";

export default {
  components: {
    ModalDelete,
  },
  methods: {
    async deleteCoupon(couponId) {
      let url = `coupons/${couponId}`;

      this.$api
        .delete(url)
        .then(() => {
          this.coupons = this.coupons.filter((item) => item.id !== couponId);
          this.message = "Cupom excluído com sucesso!";
          setTimeout(() => {
            this.message = "";
          }, 1200);
        })
        .catch((err) => {
          console.log(err);
          alert("erro ao deletar");
        })
        .finally(() => (this.dialog = false));
    },
  },
  data() {
    return {
      coupons: [],
      loading: false,
      message: "",
      headers: [
        { text: "Id", value: "id" },
        { text: "Descricao", value: "description" },
        { text: "Valor", value: "value" },
        { text: "Tipo desconto", value: "discount_type" },
        { text: "Tipo cupom", value: "coupon_type" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  async mounted() {
    this.loading = true;
    const resp = await getListCoupons();
    console.log("Resp: ", resp);
    this.loading = false;
    this.coupons = resp;
  },
};
</script>
