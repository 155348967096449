<template>
  <CreatePlan />
</template>


<script>
  import CreatePlan from "@/components/Plans/CreatePlan";
  export default {
      components: {
          CreatePlan
      }
  }
</script>