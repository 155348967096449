<template>
  <div>
    <Layout>
      <template #content>
        <div class="containerContent-payment">
          <h1 class="containerContent-payment-title">
            Formas de pagamentos salvos
          </h1>
          <p class="containerContent-payment-desc">
            <img src="@/assets/check.svg" alt="" /> Gerencie suas informações de
            pagamento para compras de assinaturas. Para ver os detalhes da sua
            assinatura, vá para a Visão geral da conta.
          </p>
          <form @submit.prevent="submitForm" ref="form">
            <div class="containerContent-payment-contact">
              <!-- <span class="containerContent-payment-email">••• 5566 | 06/26
              </span> -->
              <button type="button" @click="formSelect()">Alterar forma de pagamento</button>
            </div>
            <div v-if="form" class="containerContent-coupon-contact">
              <div class="input">
                <v-label class="containerContent-coupon-contact-label">Forma de pagamento</v-label>
                <v-select class="containerForm-input" v-model="paymentType" :items="['Cartão', 'PIX']" solo></v-select>
              </div>
              <div class="credit-card" v-if="paymentType === 'Cartão'">
                <h3> Confimar dados do titular</h3>
                <div class="input">
                  <v-label class="containerContent-coupon-contact-label">Nome do titular do cartão</v-label>
                  <v-text-field class="containerForm-input" v-model="creditCardHolderInfo.name" solo></v-text-field>
                </div>

                <div class="input">
                  <v-label class="containerContent-coupon-contact-label">Email</v-label>
                  <v-text-field class="containerForm-input" v-model="creditCardHolderInfo.email" solo></v-text-field>
                </div>

                <div class="input">
                  <v-label class="containerContent-coupon-contact-label">CPF/CNPJ</v-label>
                  <v-text-field class="containerForm-input" v-model="creditCardHolderInfo.cpfCnpj" v-mask="doc_mask"
                    solo></v-text-field>
                </div>

                <div class="input">
                  <v-label class="containerContent-coupon-contact-label">CEP</v-label>
                  <v-text-field class="containerForm-input" v-model="creditCardHolderInfo.postalCode"
                    v-mask="['#####-###']" solo></v-text-field>
                </div>

                <div class="input">
                  <v-label class="containerContent-coupon-contact-label">Número do Endereço</v-label>
                  <v-text-field class="containerForm-input" v-model="creditCardHolderInfo.addressNumber"
                    solo></v-text-field>
                </div>

                <div class="input">
                  <v-label class="containerContent-coupon-contact-label">Telefone</v-label>
                  <v-text-field class="containerForm-input" v-model="creditCardHolderInfo.phone"
                    v-mask="['(##) #####-####']" solo></v-text-field>
                </div>

                <h3> Dados do Cartão </h3>

                <div class="input">
                  <v-label class="containerContent-coupon-contact-label">Número do cartão</v-label>
                  <v-text-field class="containerForm-input" v-mask="['#### #### #### ####']" v-model="creditCard.number"
                    solo></v-text-field>
                </div>

                <div class="doubleInput">
                  <div class="input">
                    <v-label class="containerContent-coupon-contact-label">Mês de vencimento</v-label>
                    <v-text-field class="containerForm-input" :maxlength="2" v-model="creditCard.expiryMonth"
                      solo></v-text-field>
                  </div>

                  <div class="input">
                    <v-label class="containerContent-coupon-contact-label">Ano de vencimento</v-label>
                    <v-text-field class="containerForm-input" :maxlength="4" hint="Ex: 2029" persistent-hint
                      v-model="creditCard.expiryYear" solo></v-text-field>
                  </div>
                </div>

                <div class="input">
                  <v-label class="containerContent-coupon-contact-label">Código de segurança</v-label>
                  <v-text-field class="containerForm-input" :maxlength="3" v-model="creditCard.ccv" solo></v-text-field>
                </div>
              </div>

              <v-btn block type="submit" :loading="loader" color="containerForm-btn">
                Salvar
              </v-btn>

              <v-snackbar v-model="toast.show" :color="toast.type" :timeout="5000">
                {{ toast.message }}

                <div v-if="toast.errors.length > 0">
                  <p style="color: white" v-for="(error, index) in toast.errors" :key="index"> - {{ error.description }}
                  </p>
                </div>


                <template v-slot:actions>
                  <v-btn color="white" variant="text" @click="toast.show = false">
                    Close
                  </v-btn>
                </template>
              </v-snackbar>

              <v-dialog persistent v-model="errorDialog.show" width="500">
                <v-card class="v-card-home ">
                  <v-card-text class="v-card-text-error">
                    <span class="span-card-home error-text"> {{ errorDialog.message }}</span>
                    <br>
                    <div class="div-error-list" v-if="errorDialog.errors.length > 0">
                      <p style="color: black" v-for="(error, index) in errorDialog.errors" :key="index"> - {{ error }}</p>
                    </div>
                  </v-card-text>
                  <v-card-actions class="footer-error-dialog">
                    <v-btn class="btn-home-modal" color="black" @click="errorDialog.show = false">Ok</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </form>

        </div>
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from "@/layouts/HomeLayout.vue";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      form: false,
      loader: false,
      billingType: '',
      toast: {
        show: false,
        type: 'success',
        message: '',
        errors: []
      },
      errorDialog: {
        show: false,
        message: '',
        errors: []
      },
      creditCard: {
        number: '',
        expiryMonth: '',
        expiryYear: '',
        ccv: ''
      },
      creditCardHolderInfo: {
        name: '',
        email: '',
        cpfCnpj: '',
        postalCode: '',
        addressNumber: '',
        phone: '',
        mobilePhone: ''
      },
      paymentType: "",
    };
  },
  methods: {
    formSelect() {
      this.form = !this.form;
    },
    submitForm() {
      this.loader = true;
      this.creditCard.number = this.creditCard.number.replace(/\s/g, '');
      const user = JSON.parse(localStorage.getItem('user'))

      let body = {
        billingType: this.getPaymentType(),
      }

      if (this.paymentType === 'Cartão') {

        if (this.creditCard.expiryYear.length < 4) {
          alert('O ano de expiração deve ser extenso. Ex: 2029');
          this.loader = false;
          return;
        }

        body.creditCard = {
          ...this.creditCard,
          holderName: this.creditCardHolderInfo.name
        },
          body.creditCardHolderInfo = {
            ...this.creditCardHolderInfo,
            mobilePhone: this.creditCardHolderInfo.phone
          }
      }

      this.$api
        .post('/asaas/' + user.id + '/change_payment_mode', body)
        .then((resp) => {
          if (resp.status == 200) {
            // console.log(resp);
            this.toast.errors = [];
            this.errorDialog.errors = [];

            if (!!resp.data.body.errors && resp.data.body.errors.length > 0) {
              // this.toast.message = 'Algo deu errado. Reveja os dados e tente novamente!'
              // this.toast.type = 'error',
              // this.toast.errors = resp.data.body.errors
              // this.toast.show = true;

              this.errorDialog.show = true;
              this.errorDialog.message = 'Algo deu errado. Confira os dados e tente novamente!';
              this.errorDialog.errors = resp.data.body.errors;

              return
            }

            this.toast.message = 'Forma de pagamento alterada com sucesso!'
            this.toast.type = 'success',
              this.toast.show = true;
          } else {
            this.toast.message = 'Algo deu errado. Reveja os dados e tente novamente!'
            this.toast.type = 'error',
              this.toast.show = true;
          }
        }).catch(() => {
          this.toast.message = 'Algo deu errado. Reveja os dados e tente novamente!'
          this.toast.type = 'error',
            this.toast.show = true;
        }).finally(() => {
          this.loader = false;
        });

    },
    getPaymentType() {
      switch (this.paymentType) {
        case "PIX":
          return "PIX";
        case "Boleto":
          return "BOLETO";
        case "Cartão":
          return "CREDIT_CARD";
        default:
          return "CREDIT_CARD";
      }
    },
  },

  computed: {
    doc_mask() {
      if (this.creditCardHolderInfo.cpfCnpj.length <= 14) {
        return "###.###.###-##"; // CPF mask
      } else {
        return "##.###.###/####-##"; // CNPJ mask
      }
    },
  },
};
</script>
<style scoped>
.v-card-text-error {
  text-align: center;
}

.error-text {
  color: red !important;
  font-weight: bold
}

.div-error-list {
  margin-top: 10px;
  text-align: justify
}

.footer-error-dialog {
  display: flex;
  justify-content: center
}

.v-card-home {
  padding-top: 24px;
}

.span-card-home {
  font-size: 18px;
  color: black;
}

.btn-home-modal {
  width: 50%;
  color: white !important;
}

.containerContent-payment {
  max-width: 730px;
  width: 100%;
  margin: 0 auto;

  background: #fff;
  border-radius: 8px;
  margin-top: 40px;
  padding: 30px;
}

.doubleInput {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.doubleInput .input {
  width: 49% !important;
}

.bold {
  font-weight: bold;
}

.flex {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 10px;
}

.flex-print {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.printBtn .v-btn {
  width: 40% !important;
  min-width: none;
}

.containerContent-payment-title {
  font-size: 26px;
  padding: 0px;
  font-weight: bold;
  margin-bottom: 25px;
}

.containerContent-payment-desc {
  font-size: 16px;
  margin-bottom: 40px;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  max-width: 520px;
}

.containerContent-payment-contact {
  background-image: url(../assets/content_support_email.png);
  background-repeat: no-repeat;
  background-position: center;
  min-height: 92px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 75px;
}

.containerContent-payment-email {
  font-size: 15px;
}

@media (max-width: 1023px) {
  .containerContent-payment-contact {
    background-position: unset;
    padding-left: 60px;
  }

  .containerContent-payment {
    padding: 10px;
  }
}

.containerContent-payment-contact {
  display: flex;
  justify-content: space-between;
  padding-right: 24px;
}

.containerContent-payment-contact button {
  color: #417505;
  font-size: 15px;
  font-weight: 900;
}

.containerContent-coupon-contact .v-label {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.containerContent-coupon-contact {
  padding: 20px 50px;
}
</style>
