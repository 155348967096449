<template>
  <div>
    <v-file-input @change="uploadFile" :label="label" truncate-length="125" />
    <v-alert v-if="bufferValue > 0">
      {{ bufferValue }} %
    </v-alert>
    <v-progress-linear v-if="bufferValue > 0" :value="bufferValue"></v-progress-linear>
    <div :id="'loadingAws_' + this.$props.fieldName" style="text-align: center; display: none; margin-top: 20px">
      <v-progress-circular indeterminate color="green"></v-progress-circular>
      <p> Sincronizando com a Amazon...</p>
    </div>
  </div>
</template>

<script>
import { DirectUpload } from '@rails/activestorage';
import S3 from "aws-s3";
export default {
  computed: {
    loadPercentret() {
      return this.loadPercent
    },
    s3Keys() {
      return this.$auth.user.s3_keys;
    },
    config() {
      return {
        bucketName: "weflowapp",
        region: "sfo3",
        acl: 'public-read',
        accessKeyId: "V55IHDRFGGENUCW5UXPO",
        secretAccessKey: "kMf6S25sDXYnQQ4VQiXTBiLTZqv2GfY83U",
        s3Url: "https://weflowapp.sfo3.digitaloceanspaces.com",
      };
    },
    configAws() {
      return {
        bucketName: "weflowapp",
        region: "sa-east-1",
        accessKeyId: "AKIA2V5XPX423BLHSS64",
        secretAccessKey: "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",
      };
    },
    baseURL() {
      return "https://vue-screencasts-uploads.s3-us-west-2.amazonaws.com";
    },
    S3Client() {
      return new S3(this.config);
    },
    S3ClientAmazon() {
      return new S3(this.configAws);
    },
    newFileName() {
      return Math.random().toString().slice(2);
    },
    url() {
      return `${this.baseURL}/${this.directory}/${this.newFileName}`;
    },
  },
  methods: {
    test(file) {
      console.log(file)
    },
    changeAwsLoader() {
      this.awsSync = !this.awsSync;
    },
    onProgress(event) {
      console.log(parseInt(event.loaded / this.fileSize * 100))
      this.bufferValue = parseInt(event.loaded / this.fileSize * 100)
    },
    directUploadWillStoreFileWithXHR(xhr) {
      xhr.upload.addEventListener("progress", event => this.onProgress(event))
    },
    uploadFile(file) {
      this.fileSize = file.size;

      //const uploadType = this.$props.fieldName;

      const upload = new DirectUpload(file, 'https://api.weflowoficial.com/rails/active_storage/direct_uploads', {
        directUploadWillStoreFileWithXHR: (xhr) => {
          console.log('Direct upload xhr: ', xhr);
          // TODO: REMOVE NEXT LINE WHEN MIRROR IS OVER 
          xhr.setRequestHeader('x-amz-acl', 'public-read');
          xhr.upload.addEventListener("progress", event => this.onProgress(event))
        }
      })
      // console.log("file: ", file)
      // console.log("upload: ", upload);
      upload.create(async (error, blob) => {
        console.log('blob before error: ', blob);

        if (error) {
          // Handle the error
          console.log('blob: ', blob);
          console.log('error: ', error);
        } else {

          // document.getElementById('loadingAws_' + uploadType).style.display = 'block';
          // TODO: REMOVE WHEN MIRROR IS OVER
          //const aws = require('aws-sdk');

          //aws.config.update({
          //  accessKeyId: 'AKIA2V5XPX423BLHSS64',
          //  secretAccessKey: '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',
          //  region: 'sa-east-1'
          //});

          //const s3 = new aws.S3();
          //s3.upload({
          //  Bucket: "weflowapp",
          //  Key: blob.key,
          //  Body: file,
          //  ContentType: 'video/mp4'
          //}, function (err, data) {
          //  // this.awsSync = !this.awsSync;
          //  console.log(data);
          //  if (!!data.Location && data.Location.length > 0) {
          //    console.log('success');
          //    document.getElementById('loadingAws_' + uploadType).style.display = 'none';
          //  }
          //  if (err) {
          //    document.getElementById('loadingAws_' + uploadType).style.display = 'none';
          //    console.log('error in callback');
          //    alert('Erro ao sincronizar com a Amazon.');
          //    console.log(err);
          //  }
          //}
          //);

          this.$api.put(`https://api.weflowoficial.com/api/v1/class_items/${this.$route.params.id}`, {
            class_item: { [this.$props.fieldName]: blob.signed_id }
          }).then((resp) => {
            // this.awsSync = false;
            console.log(resp)
          }).catch((err) => {
            console.log("erro", err)
          })
          // console.log(blob)
        }
      })
    },
  },
  data: () => ({
    isLoading: false,
    loadPercent: 0,
    bufferValue: 0,
    fileSize: 0,
    awsSync: false,
    label: "Adicionar Vídeo",
    newUri: '',
  }),
  props: ["fieldName", "obj", "directory"],
};
</script>
