<template>
  <v-container>
    <v-card :loading="loading" class="mx-auto my-12" max-width="374">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-img
        height="100"
        class="logo-form"
        src="/assets/logo_green.png"
      ></v-img>

      <v-form @submit.prevent="submit" ref="form">
        <v-card-text>
          <v-alert type="success" v-if="successMsg">
            Plano salvo com sucesso!
          </v-alert>
          <v-row align="center" class="mx-0">
            <v-text-field
              v-model="form.title"
              label="Título"
              :rules="[(v) => !!v || 'Título é obrigatório']"
              hide-details="auto"
            ></v-text-field><br />
           
            <v-text-field
              v-model="form.description"
              label="Descrição"
              :rules="[(v) => !!v || 'Descrição é obrigatório']"
              hide-details="auto"
            ></v-text-field>

             <v-text-field
              v-model="form.months"
              label="Recorrência em meses"
              type="months"
              :rules="[(v) => !!v || 'Recorrencia é obrigatório']"
              hide-details="auto"
            ></v-text-field>

            <v-text-field
              v-model="form.value"
              label="Valor do plano"
              type="value"
              class="text"
              :rules="[(v) => !!v || 'Descrição é obrigatório']"
              hide-details="auto"
            ></v-text-field>
            
            <br />

            <v-text-field
              v-model="form.stores_id"
              label="Identificador da loja"
              type="value"
              class="text"
              :rules="[(v) => !!v || 'Descrição é obrigatório']"
              hide-details="auto"
            ></v-text-field><br />

            <v-select
              v-model="form.user_type"
              :items="types"
              item-text="title"
              item-value="id"
              label="Selecione o tipo do plano"
              outlined
              dense
            ></v-select>

            <v-checkbox
              style="width: 100%; margin-top: 0;"
              v-model="form.permit_installments"
              :readonly="true"
              :label="`Compra única?`"
            />
            
            <v-template v-if="form.permit_installments == 1" style="width: 100%;">
              <v-text-field
              v-model="form.min_installments"
              label="Min"
              type="number"
              class="text"
              :min="1"
              :max="12"
              hide-details="auto"
            ></v-text-field>

            <v-text-field
              v-model="form.max_installments"
              label="Max"
              type="number"
              class="text"
              :min="1"
              :max="12"
              hide-details="auto"
            ></v-text-field>

            </v-template>
              
            <v-textarea
              v-model="form.benefits"
              label="Benefícios do plano (separar com ;)"
              hint="Exemplo: 1 mês de acesso; Download de vídeos"
              auto-grow
            ></v-textarea>    

          
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-btn block type="submit" color="primary">
            <span v-if="loading"> Carregando </span>
            <span v-else> Enviar </span>
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>


<script>
export default {
  mounted() {
    this.retrieveCategory()
  },
  methods: {
    async retrieveCategory(){
      const resp = await this.$api.get(`/payment_plans/${this.$route.params.id}`)
      if(resp.status == 200){
        this.form = {...resp.data}
      }else{
        alert("erro")
      }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let id = this.$route.params.id;
        let url =
          `/payment_plans/${id}`;

        const resp = await this.$api.put(url, {
          payment_plan: {...this.form},
        });
        if (resp.status === 200) {
          this.loading = false;
          this.successMsg = true;
        } else {
          this.loading = false;
        }
      }
    },
  },
  data: () => ({
    types: [
      {title: 'Professor', id: 'teacher'},
      {title: 'Aluno', id: 'student'},
    ],
    formValid: true,
    loading: false,
    successMsg: false,
    validations: {},
    form: {
     title: "",
     stores_id: "",
     description: "",
     value: 0,
     months: 1,
     permit_installments: 0,
     min_installments: 0,
     max_installments: 0,
     benefits: ""
    },
  }),
};
</script>

<style scoped>
.text{
  margin-bottom: 30px;
}
.logo-form {
  width: 100px;
  margin-left: 35%;
  margin-top: 20px;
  height: 100px;
}
</style>