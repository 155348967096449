import axios from "axios";
import Vue from "vue";

const elastic_api = axios.create({
  baseURL: process.env.VUE_APP_URL_ES,
});

const api = axios.create({
  baseURL: 'https://api.weflowoficial.com/api/v1',
  // baseURL: "http://localhost:3000/api/v1",
});

// axios.defaults.baseURL = 'https://api.weflowoficial.com/api/v1'
elastic_api.defaults.headers.common[
  "Authorization"
] = `Bearer ${localStorage.getItem("token")}`;

// elastic_api.defaults.headers.common[
//   'Authorization'
// ] = `Bearer ${localStorage.getItem('token')}`
api.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "token"
)}`;

Vue.prototype.$es = elastic_api;
Vue.prototype.$api = api;
