<template>
  <v-container>
    <v-btn color="success" @click="$router.push('/createClassItem')">
      Cadastrar Aula
      <v-icon>
        mdi-plus
      </v-icon>
    </v-btn>
    <br />
    <v-alert v-if="message !== ''" type="success">
      {{ message }}
    </v-alert>
    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details
      style="margin-top: 10px; margin-bottom: 10px"></v-text-field>
    <v-data-table :loading="loading" :headers="headers" :items="classItems" :items-per-page="5" :search="search"
      class="elevation-1">
      <template v-slot:item.image="{ item }">
        <img width="150" height="100" :src="item.image" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn color="blue" @click="$router.push(`/editClassItem/${item.id}`)" icon>
          <v-icon small class="mr-2">
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn color="error" icon>
          <ModalDelete @click="deleteClassItem(item.id)" />
        </v-btn>
        <v-btn color="green" @click="$router.push(`addMediaContent/${item.id}`)" icon>
          <v-icon small>
            mdi-video
          </v-icon>
        </v-btn>
        <v-btn color="purple" @click="$router.push(`classItems/${item.id}/comments`)" icon>
          <v-icon small>
            mdi-comment
          </v-icon>
        </v-btn>
        <v-btn v-if="!!item.video && !!item.graphic" color="orange" icon>
          <ModalNotification :item="item" @dialog-response="sendNotification" />
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import ModalDelete from '@/components/shared/ModalDelete';
import ModalNotification from './shared/ModalNotification.vue';
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    ModalDelete,
    ModalNotification
  },
  methods: {
    ...mapActions("User", ["REQUEST_USERS"]),
    async deleteClassItem(classId) {
      let url = `/class_items/${classId}`;

      this.$api.delete(url).then(() => {
        this.classItems = this.classItems.filter((item) => item.id !== classId)
        this.message = "Aula excluída com sucesso!"
        setTimeout(() => {
          this.message = ""
        }, 1200)
      }).catch(err => {
        console.log(err)
        alert("erro ao deletar");
      }).finally(() => this.dialog = false);
    },
    async sendNotification(data) {
      // console.log('data: ', data);
      const _classItem = data.item;
      const url = `/notify_all`;

      this.$api.post(url, {
        title: 'Nova aula!',
        description: _classItem.title + ' - ' + _classItem.teacher_name,
        notification_data: {
          "lesson_id": _classItem.id
        },
        user_id: this.GET_USER.id
      });
    }
  },
  computed: {
    currentUser() {
      return localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : this.userDefault
    },
    retrievedUsers() {
      return this.users();
    },
    ...mapGetters('User', ['GET_USER'])
  },
  data() {
    return {
      ...mapGetters("User", {
        users: "GET_USERS",
        default_user: "GET_USER"
      }),
      classItems: [],
      headers: [
        { text: "Id", value: "id" },
        { text: "Titulo", value: "title" },
        { text: "Descrição", value: "description" },
        { text: "Thumbnail", value: "image" },
        { text: "Data de criação", value: "created_at" },
        { text: 'Actions', value: 'actions', sortable: false },

      ],
      message: "",
      dialog: false,
      loading: false,
      search: '',
    };
  },

  async mounted() {
    this.loading = true;
    let url = "/class_items";
    const resp = await this.$api.get(url);
    if (resp.data) {
      this.classItems = resp.data;
      this.loading = false;
      this.successMsg = true;
    } else {
      this.loading = false;
    }
  },
};
</script>