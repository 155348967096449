import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    paymentPlans: [],
    selectedPlan: {},
    currentStep: 1
  },
  mutations: {
    SET_USER(state, value) {
      state.user = value
    },
    SET_SELECTED_PLAN(state, value){
      state.selectedPlan = value;
    },
    SET_CURRENT_STEP(state, value){
      state.currentStep = value;
    },
    SET_PLANS(state, value){
      state.paymentPlans = value
    },
    SET_LOADING_USERS(state,value){
      state.payment_plans = value
    },
    PREVIOUS_STEP(state){
     state.currentStep = state.currentStep - 1;
    },
    NEXT_STEP(state){
      state.currentStep = state.currentStep + 1;
    },
  },
  actions: {
    async SET_PLAN({ commit }, plan) {
      commit('SET_SELECTED_PLAN', plan)
    },
    SET_CURRENT_STEP({ commit }, plan){
      commit('SET_CURRENT_STEP', plan)
    },
    PREVIOUS_STEP({commit}){
      commit('PREVIOUS_STEP')
    },
    NEXT_STEP({commit}){
      commit('NEXT_STEP')
    },
    async GET_PLANS({ commit }){
       const resp = await Vue.prototype.$api.get('/payment_plans')
       if(resp.data){
         commit('SET_PLANS', resp.data)
       }
    },
    async SET_TOKEN(_state, payload) {
      if (!payload) {
        localStorage.removeItem('token')
        Vue.prototype.$api.defaults.headers.common['Authorization'] = ''
        return true
      }
      localStorage.setItem('token', payload)
      Vue.prototype.$api.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${payload}`
      return true
    },
  },
  getters: {
    GET_PLANS_LIST({ paymentPlans }) {
      return paymentPlans
    },
    GET_CURRENT_STEP({ currentStep }) {
      return currentStep
    },
    GET_SELECTED_PLAN({ selectedPlan }) {
      return selectedPlan
    },
  },
}
