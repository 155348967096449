<template>
    <AddPayment />
</template>

<script>
import AddPayment from '@/components/Users/AddPayment.vue';

export default {
    components: {
        AddPayment
    }
}
</script>