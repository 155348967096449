import { getDashData } from "../services/Dashboard";

export default {
  namespaced: true,

  actions: {
    async REQUEST_DASHBOARD() {
      const response = await getDashData();
      console.log(response);
      return response;
    },
    getToken() {
      return JSON.stringify(localStorage.getItem("token"));
    },
  },
};
