// import axios from "axios";
import Vue from 'vue';

// const BASE_URL = "http://localhost:3000/api/v1";

function getListPlans() {
  // const url = `${BASE_URL}/payment_plans`;

  return Vue.prototype.$api.get('/payment_plans').then((x) => x.data);
}

export { getListPlans };
