<template>
    <div class="containerCreate-form">
        <Header>
            <template #logo>
                <Logo />
            </template>
            <template #user>
                <User />
            </template>
            <template class="mobile" #menu>
                <OpenMenu />
            </template>
        </Header>


        <div class="title-box">
            <h2>Escolha um plano para começar!</h2>
        </div>
        <div class="center">
            <div v-if="card.length <= 0">
                <v-progress-circular indeterminate color="green"></v-progress-circular>
            </div>

            <div v-if="card.length > 0" class="containerManageSubscription">
                <Card :create="true" :onclick="getPlan" :changePlan="false" :isPopulate="true" :plansObj="i"
                    v-for="i in card" :key="i" />
                <Card :created="true" :create="true" :onclick="toFreePlan" :plansObj="0" :isPopulate="false" />
            </div>
        </div>

    </div>
</template>
<script>
import Header from '@/components/Layout/Header/Header.vue';
import User from '@/components/Layout/Header/User/User.vue';
import Logo from '@/components/Layout/Header/Logo.vue';
import { mapActions } from 'vuex';

import Card from '@/components/Layout/CardSubscription/CardSubscription.vue'
import router from '../router/index'


export default {
    components: {
        Header,
        User,
        Logo,
        Card
    },
    data() {
        return {
            card: []
        }
    },
    computed: {

    },
    methods: {
        ...mapActions("Plan", ["REQUEST_PLANS"]),

        async getList() {
            // const resp = await this.$api.get('/all_plans')
            const resp = await this.$api.get('/payment_plans')
            // console.log(resp);
            this.card = resp.data;
        },
        getPlan(i) {
            router.push(`/createAccount-v2/${i}`)
        },
        toFreePlan() {
            console.log('To free plan');
            router.push(`/createAccount-v2/0`);
        }

    },
    created() {
        this.getList()
    },

}
</script>
<style>
*,
p,
h2,
h3,
h4 {
    font-family: "Roboto", sans-serif !important;
}



.containerCreate-form {
    background-color: #f8f8f8;
    height: 100%;
}

.title-box {
    margin: 1rem 2rem;

    display: flex;
    justify-content: center;
    background-color: black;
    border-radius: 15px;
}

.title-box h2 {
    padding: 20px;
    color: #4eba46;
    font-size: 32px;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerManageSubscription {
    /*padding: 40px 0 40px 35px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;*/
    display: grid;
    gap: 1rem;
    padding: 40px 60px 40px 60px;
    margin-inline: 40px;
}

@media (min-width: 600px) {
    .containerManageSubscription {
        margin-inline: 0 !important;
        grid-template-columns: repeat(2, 1fr);
        padding: 0;
    }
}

/* Screen larger than 900px? 3 columns */
@media (min-width: 900px) {
    .containerManageSubscription {
        grid-template-columns: repeat(3, 1fr);
        padding: 0 !important;
        margin-inline: 0 !important;
    }
}

@media (min-width: 1200px) {
    .containerManageSubscription {
        grid-template-columns: repeat(3, 1fr);
        padding: 20px 20px 20px 20px !important;
        margin-inline: 40px !important;
    }
}


@media (max-width: 1023px) {
    .containerManageSubscription {
        justify-content: center;
        margin: 10px 0;
        padding: 10px;
    }
}
</style>