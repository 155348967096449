import { getListPlans } from "../services/Plan";

export default {
  namespaced: true,

  actions: {
    async REQUEST_PLANS() {
      const response = await getListPlans();
      return response;
    },
  },
};
