<template>
  <div class="mobile">
    <button @click="openMenu">
      <img src="@/assets/menu.svg" alt="">
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    openMenu() {
      document.getElementById('sideMenu').classList.add('openSideMenu');
      document.getElementById('overlaySideMenu').classList.add('openSideMenu');
    }
  },
};
</script>
<style>
.fa-list:before {
  content: "\f03a";
}
.openSideMenu {
  display: block!important;
}
</style>
