<template>
  <v-app-bar class="containerHeader">
    <v-toolbar-title>
      <slot name="logo" />
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <slot name="user"  />
    <slot name="menu" />
  </v-app-bar>
</template>

<script>
export default {

}
</script>

<style scoped>
.containerHeader {
  min-height: 110px;
  background-color: #000000!important;
  box-shadow: none!important;
  position: unset!important;
}

</style>
<style>
.containerHeader .v-toolbar__content {
  height: 110px!important;
}
@media (max-width: 1023px) {
  .containerHeader {
    min-height: 70px!important;
  }
  .containerHeader .v-toolbar__content {
    height: 70px!important;
  }
  .containerHeader .containerHeader-user {
    display: none!important;
  }
}
</style>