<template>
    <v-container>
        <v-card :loading="loading" class="mx-auto my-12" max-width="374">
            <template slot="progress">
                <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
            </template>

            <v-card-title>
                Criar Cupom
            </v-card-title>

            <v-form @submit.prevent="submit" ref="form">
                <v-card-text>
                    <div v-if="successMsg">
                        <v-alert type="success">
                            Cupom criado com sucesso!
                        </v-alert>
                        <v-btn @click="$router.push(`/coupons`)">
                            <v-icon>
                                mdi-arrow-left-circle
                            </v-icon>
                            Todos cupons
                        </v-btn>
                    </div>
                    <v-row v-else class="mx-0" style="flex-direction: column;">
                        <v-text-field v-model="form.description" label="Descrição"
                            :rules="[(v) => !!v || 'Descrição é obrigatório']" hide-details="auto"></v-text-field>
                        <v-text-field v-model="form.initial_date" class="mt-1r" type="date" label="Data Inicial"
                            :rules="[(v) => !!v || 'Data Inicial é obrigatório']" hide-details="auto"></v-text-field>

                        <v-text-field v-model="form.final_date" class="mt-1r" type="date" label="Data Final"
                            :rules="[(v) => !!v || 'Data Final é obrigatório']" hide-details="auto"></v-text-field>

                        <v-text-field v-model="form.value" class="mt-1r" type="number" label="Valor"
                            :rules="[(v) => !!v || 'Valor é obrigatório']" hide-details="auto"></v-text-field>

                        <v-select v-model="form.coupon_type" class="mt-1r" :items="couponTypes" item-text="title"
                            item-value="id" label="Selecione o tipo do cupom" outlined dense></v-select>

                        <v-select v-model="form.discount_type" class="mt-1r" :items="discountTypes" item-text="title"
                            item-value="id" label="Selecione o tipo de desconto" outlined dense></v-select>

                        <v-select v-model="form.planId_id" :items="plans" item-text="title" item-value="id"
                            label="Selecione o plano" outlined dense></v-select>

                        <v-textarea v-model="form.whitelist" label="Lista de acesso (separar com ;)"
                            hint="Exemplo: teste@gmail.com;felipe_adm@gmail.com" auto-grow></v-textarea>


                        <v-checkbox style="width: 100%; margin-top: 0;" v-model="form.unique_use"
                            :label="`Uso único?`" />
                    </v-row>

                    <v-div v-if="!successMsg">
                        <v-file-input style="margin-top: 15px;" v-model="file" :label="'Selecionar planilha'"
                            truncate-length="125" />
                    </v-div>

                    <v-btn v-if="!successMsg" type="button" @click="readCsv" color="success">
                        Usar planilha
                    </v-btn>
                </v-card-text>

                <v-card-text v-if="!successMsg">
                    <v-btn block type="submit" color="primary">
                        <span v-if="loading"> Carregando </span>
                        <span v-else> Enviar </span>
                    </v-btn>
                </v-card-text>
            </v-form>
        </v-card>
    </v-container>
</template>


<script>
import { mapActions } from "vuex";

export default {
    methods: {
        ...mapActions("User", ["LOGIN_USER"]),
        togglePassword() {
            if (this.passwordType === "password") {
                this.passwordType = "type";
            } else {
                this.passwordType = "password";
            }
        },
        async retrievePlans() {
            this.loading = true;

            try {
                const resp = await this.$api.get("/payment_plans")
                this.plans = resp.data;
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false;
            }
        },
        async submit() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                let url =
                    "/coupons";

                const resp = await this.$api.post(url, {
                    coupon: {
                        description: this.form.description.toUpperCase(),
                        initial_date: this.form.initial_date,
                        final_date: this.form.final_date,
                        value: this.form.value,
                        coupon_type: this.form.coupon_type,
                        discount_type: this.form.discount_type,
                        planId_id: this.form.planId_id,
                        whitelist: this.form.whitelist,
                    },
                });
                if (resp.data) {
                    this.loading = false;
                    this.successMsg = true;
                } else {
                    this.loading = false;
                }
                console.log(resp);
            }
        },
        readCsv() {
            if (this.file != null) {
                var form = this.form;
                this.$papa.parse(this.file, {
                    complete: function (results) {
                        let newVal = '';

                        results.data.forEach(element => {
                            if (element[0] != '') {
                                newVal += element[0] + ';'
                            }
                        });

                        if (form.whitelist.slice(-1) === ';' || form.whitelist.length === 0) {
                            form.whitelist = form.whitelist + newVal;
                        } else {
                            form.whitelist = form.whitelist + ';' + newVal;
                        }
                    }
                });
            }
        }
    },
    data: () => ({
        formValid: true,
        loading: false,
        file: null,
        successMsg: false,
        validations: {},
        plans: [],
        couponTypes: [
            { id: "first_only", title: "Desconto primeira cobrança" },
            { id: "discount_all", title: "Desconto em toda assinatura" },
        ],
        discountTypes: [
            { id: "percent", title: "Porcentagem" },
            { id: "value", title: "Valor" }
        ],
        form: {
            description: "",
            initial_date: "",
            final_date: "",
            value: "",
            planId_id: null,
            whitelist: "",
            coupon_type: 'first_only',
            discount_type: 'percent',
            unique_use: true
        },
    }),
    mounted() {
        this.retrievePlans()
    },
};
</script>

<style scoped>
.mt-1r {
    margin-top: 1rem;
}

.logo-form {
    width: 100px;
    margin-left: 35%;
    margin-top: 20px;
    height: 100px;
}
</style>