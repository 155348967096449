<template>
  <div class="containerCreate-form">
    <v-overlay :value="loader">
      <v-progress-circular indeterminate color="green" size="64"></v-progress-circular>
    </v-overlay>
    <Header>
      <template #logo>
        <Logo />
      </template>
      <template #user>
        <User />
      </template>
      <template class="mobile" #menu>
        <OpenMenu />
      </template>
    </Header>
    <div class="grid-content">
      <div class="container-forms">
        <h3>{{ plan.title }}</h3>

        <v-card-text class="cardSubscription-price bgPrice">
          <div class="text-center">
            <div class="headlineAlone">
              R$ {{ plan.value.toFixed(2).replaceAll(".", ",") }}
            </div>
          </div>
        </v-card-text>

        <v-list class="cardSubscription-flat">
          <v-list-item class="cardSubscription-flat-listItem  benefits">
            <div class="icons" v-for="benefit in planBenefits" :key="benefit">
              <v-list-item-icon>
                <v-icon>mdi-check</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ benefit }}
              </v-list-item-content>
            </div>
          </v-list-item>
        </v-list>

        <v-list class="cardSubscription-flat">
          <v-list-item class="cardSubscription-flat-listItem">
            <v-list-item-icon>
              <v-icon color="white">
                mdi-check
              </v-icon>
            </v-list-item-icon>
            <div class="">
              <v-list-item-content>Total: R$
                {{ 
                  plan_installments > 0 
                    ? '12x ' + (totalValue/12).toFixed(2) 
                    :  totalValue.toFixed(2).replaceAll(".", ",") 
                }}
              </v-list-item-content>
              <v-list-item-content>
                <p class="payment-info"> {{ plan_installments > 0 ? 'Pagamento parcelado' : 'Pagamento à vista' }}</p>
              </v-list-item-content>
              <v-list-item-content>
                <span class="typePlan">Sem Cobrança</span>
              </v-list-item-content>
            </div>
          </v-list-item>
        </v-list>

        <v-list v-if="plan.value > 0.0" class="cardSubscription-flat" style="padding-bottom: 0;">
          <v-list-item class="cardSubscription-flat-listItem benefits">
            <div class="icons">
              <v-list-item-icon>
                <v-icon>mdi-ticket-percent</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Adicionar cupom
              </v-list-item-content>
            </div>

            <div class="icons">
              <v-text-field @blur="searchCoupon()" class="containerForm-input" v-model="coupon" solo>
                <v-icon slot="append" style="cursor: pointer;" @click="searchCoupon" color="black">
                  mdi-magnify
                </v-icon>
              </v-text-field>

              <div v-if="couponId" @click="clearCoupon"
                style="align-self: start; margin-top: 0.5rem; margin-left: 10px; cursor: pointer">
                <v-icon slot="append" color="red">
                  mdi-close
                </v-icon>
              </div>
            </div>
            <div style="display: flex" v-if="!!couponData">
              <span class="typePlan" style="padding-inline: 10px; margin-right: 5px; font-weight: bolder">
                {{
                  couponData.coupon_type == "discount_all"
                    ? "Toda assinatura"
                    : "Primeira cobrança"
                }}
              </span>
              <span class="typePlan" style="padding-inline: 10px; font-weight: bolder">
                - {{ couponData.value
                }}{{ couponData.discount_type == "percent" ? "%" : "R$" }}</span>
            </div>
          </v-list-item>
        </v-list>

        <div class="service">
          <p>Suporte:</p>
          <div class="icons">
            <v-icon small>mdi-email</v-icon>
            <span> suporte@weflowoficial.com </span>
          </div>
        </div>
      </div>
      <div class="container-forms">
        <h3>Dados Cadastrais</h3>

        <div class="containerContent-coupon-contact">
          <v-form @submit.prevent="submitForm" ref="form">
            <div class="centerCreate">
              <div id="conteudoForm" v-if="mostraForm">
                <v-label class="containerContent-coupon-contact-label">Nome</v-label>
                <v-text-field class="containerForm-input" :maxlength="150" v-model="form.user.first_name"
                  solo></v-text-field>

                <v-label class="containerContent-coupon-contact-label">Email
                  <span style="font-size: 10px; color: green">(Utilizado para realizar login no aplicativo e
                    site)</span></v-label>
                <v-text-field @blur="checkEmail" class="containerForm-input" v-model="form.user.email"
                  solo></v-text-field>

                <div class="doubleInput">
                  <div class="input">
                    <v-label class="containerContent-coupon-contact-label">Senha
                      <span style="font-size: 10px; color: green">(Utilizada para realizar login no aplicativo e
                        site)</span></v-label>
                    <v-text-field class="containerForm-input" type="password" :rules="passwordRules"
                      v-model="form.user.password" solo></v-text-field>
                  </div>

                  <div class="input">
                    <v-label class="containerContent-coupon-contact-label">Confirmar Senha</v-label>
                    <v-text-field class="containerForm-input" type="password" v-model="confirmPassword"
                      solo></v-text-field>
                  </div>
                </div>

                <v-label class="containerContent-coupon-contact-label">Data de nascimento</v-label>
                <v-text-field class="containerForm-input" v-mask="'##/##/####'" v-model="form.user.birthdate"
                  solo></v-text-field>

                <v-label class="containerContent-coupon-contact-label">CPF/CNPJ
                  <span style="font-size: 8px; color: green">Apenas para brasileiros com endereço no Brasil. Demais
                    casos
                    faça a contratação via loja Apple/IOS ou Google/Android.*</span></v-label>
                <v-text-field class="containerForm-input" @input="validateDocument" v-mask="doc_mask"
                  v-model="creditCardHolder.cpfCnpj" solo></v-text-field>

                <v-snackbar color="error" v-model="docSnackbar">
                  Documento inválido!

                  <template v-slot:action="{ attrs }">
                    <v-btn color="white" text v-bind="attrs" @click="docSnackbar = false">
                      Fechar
                    </v-btn>
                  </template>
                </v-snackbar>

                <div class="tripleInput">
                  <div class="input">
                    <v-label class="containerContent-coupon-contact-label">CEP</v-label>
                    <v-text-field class="containerForm-input" @input="fetchAddress" v-mask="['#####-###']"
                      v-model="form.user.zip_code" solo></v-text-field>
                  </div>
                  <div class="input">
                    <v-label class="containerContent-coupon-contact-label">Estado</v-label>
                    <v-text-field class="containerForm-input" v-model="form.user.state" solo></v-text-field>
                  </div>

                  <div class="input">
                    <v-label class="containerContent-coupon-contact-label">Cidade</v-label>
                    <v-text-field class="containerForm-input" v-model="form.user.city" solo></v-text-field>
                  </div>
                </div>

                <div class="tripleInput">
                  <div class="input">
                    <v-label class="containerContent-coupon-contact-label">Endereço</v-label>
                    <v-text-field class="containerForm-input" :maxlength="150" v-model="form.user.address"
                      solo></v-text-field>
                  </div>

                  <div class="input">
                    <v-label class="containerContent-coupon-contact-label">Número</v-label>
                    <v-text-field class="containerForm-input" v-model="form.user.number" solo></v-text-field>
                  </div>

                  <div class="input">
                    <v-label class="containerContent-coupon-contact-label">Complemento</v-label>
                    <v-text-field class="containerForm-input" v-model="form.user.complement" solo></v-text-field>
                  </div>
                </div>

                <div class="doubleInput">
                  <div class="input">
                    <v-label class="containerContent-coupon-contact-label">Telefone</v-label>
                    <v-text-field class="containerForm-input" v-mask="['(##) #####-####']" v-model="form.user.phone"
                      solo></v-text-field>
                  </div>

                  <div class="input">
                    <v-label class="containerContent-coupon-contact-label">Gênero</v-label>
                    <v-select class="containerForm-input" :items="['Masculino', 'Feminino', 'Outro']"
                      v-model="form.user.gender" solo></v-select>
                  </div>
                </div>

                <div v-if="plan.value > 0.0">
                  <h3>Pagamento</h3>

                  <div class="input" v-if="plan.permit_installments">
                    <v-label class="containerContent-coupon-contact-label">Parcelas</v-label>
                    <v-select class="containerForm-input" :disabled="true" :items="plan_installments" v-model="installments"
                      solo></v-select>

                  </div>
                  <div class="input">
                    <v-label class="containerContent-coupon-contact-label">Forma de pagamento</v-label>
                    <v-select class="containerForm-input" :disabled="plan_installments > 0" v-model="paymentType" :items="paymentOptions"
                      solo></v-select>
                  </div>

                  <div class="credit-card" v-if="paymentType === 'Cartão'">
                    <div class="input">
                      <v-label class="containerContent-coupon-contact-label">Nome do titular do cartão</v-label>
                      <v-text-field v-model="creditCard.holderName" class="containerForm-input" solo></v-text-field>
                    </div>

                    <div class="input">
                      <v-label class="containerContent-coupon-contact-label">Número do cartão</v-label>
                      <v-text-field v-mask="['#### #### #### ####']" v-model="creditCard.number"
                        class="containerForm-input" solo></v-text-field>
                    </div>

                    <div class="doubleInput">
                      <div class="input">
                        <v-label class="containerContent-coupon-contact-label">Mês de vencimento</v-label>
                        <v-text-field v-model="creditCard.expiryMonth" class="containerForm-input" :maxlength="2"
                          solo></v-text-field>
                      </div>
                      <div class="input">
                        <v-label class="containerContent-coupon-contact-label">Ano de vencimento</v-label>
                        <v-text-field v-model="creditCard.expiryYear" :maxlength="4" hint="Ex: 2029" persistent-hint
                          class="containerForm-input" solo></v-text-field>
                      </div>
                    </div>

                    <div class="input">
                      <v-label class="containerContent-coupon-contact-label">Código de segurança</v-label>
                      <v-text-field v-model="creditCard.ccv" :maxlength="3" class="containerForm-input"
                        solo></v-text-field>
                    </div>
                  </div>
                </div>

                <v-btn block type="submit" :disabled="loaderSubmit" :loading="loaderSubmit" color="containerForm-btn">
                  Assinar
                </v-btn>
              </div>

              <div class="credit-card" v-if="paymentType === 'Boleto'">
                <p v-if="link.length == 0" class="bold">
                  O link de pagamento só é gerado após o clique em assinar.
                </p>
                <p v-if="link.length > 0" class="bold">
                  Clique
                  <a style="color: #6CB856" target="_blank" :href="link">
                    aqui
                  </a>
                  para acessar seu link de pagamento.
                </p>

                <p class="bold">
                  Atenção! Pode levar até 2 dias úteis para liberação
                </p>

                <!-- <p class="bold">Imprimir boleto</p>
                            <div class="flex-print">
                                <div class="printBtn">
                                    <v-btn block type="submit" color="containerForm-btn">

                                        <v-icon> mdi-printer-outline </v-icon>

                                        Imprimir
                                    </v-btn>
                                </div>

                                <p class="bold">Atenção! Pode levar até 2 dias úteis para liberação</p>
                            </div> -->
              </div>
              <div class="credit-card" v-if="paymentType === 'PIX'">
                <p v-if="link.length == 0 && !checkIsCouponFull()" class="bold">
                  O link de pagamento só é gerado após o clique em assinar.
                </p>
                <p v-if="link.length > 0" class="bold">
                  Clique
                  <a style="color: #6CB856" target="_blank" :href="link">
                    aqui
                  </a>
                  para acessar seu link de pagamento.
                </p>

                <v-btn block type="button" v-if="!mostraForm" color="containerForm-btn" @click="goToCheckout">
                  Já realizei o pagamento!
                </v-btn>
              </div>

              <!-- <div class="credit-card" v-if="paymentType === 'Cartão' && !mostraForm">
                <v-btn block type="button" color="containerForm-btn" href="/login-user">
                  Seguir para o login
                </v-btn>
              </div> -->

              <!-- <v-btn block type="button" @click="activateLoader" color="containerForm-btn">
                                Abrir model
                            </v-btn> -->
            </div>
          </v-form>
        </div>
      </div>
    </div>

    <v-dialog persistent v-model="homeDialog" width="500">
      <v-card class="v-card-home">
        <v-card-text>
          <span class="span-card-home">E-mail já cadastrado.</span>
          <br>
          <span class="span-card-home"> Realize o login para seguir com a assinatura.</span>
        </v-card-text>
        <v-card-actions>
          <v-btn class="btn-home-modal" color="green" @click="goToLogin">Ok</v-btn>
          <v-btn class="btn-home-modal" color="black" @click="homeDialog = false">Voltar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="errorDialog.show" width="500">
      <v-card class="v-card-home ">
        <v-card-text class="v-card-text-error">
          <span class="span-card-home error-text"> {{ errorDialog.message }}</span>
          <br>
          <div class="div-error-list" v-if="errorDialog.errors.length > 0">
            <p style="color: black" v-for="(error, index) in errorDialog.errors" :key="index"> - {{ error }}</p>
          </div>
        </v-card-text>
        <v-card-actions class="footer-error-dialog">
          <v-btn class="btn-home-modal" color="black" @click="errorDialog.show = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="showSuccessToast" width="500">
      <v-card class="v-card-home ">
        <v-card-text style="text-align: center;">
          <span class="span-card-home" style="color: green; font-weight: bold"> Assinatura realizada com sucesso!</span>
          <br>
        </v-card-text>
        <v-card-actions class="footer-error-dialog">
          <v-btn class="btn-home-modal" color="green" @click="goToLogin">Ir para o login</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="showFreeAccountSuccess" width="500">
      <v-card class="v-card-home ">
        <v-card-text style="text-align: center;">
          <span class="span-card-home" style="color: green; font-weight: bold"> Conta WEFLOW criada com sucesso!</span>
          <br>
        </v-card-text>
        <v-card-actions class="footer-error-dialog">
          <v-btn class="btn-home-modal" color="green" @click="goToLogin">Ir para o login</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.type" :timeout="5000">
      {{ snackbar.message }}

      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar.show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Header from "@/components/Layout/Header/Header.vue";
import User from "@/components/Layout/Header/User/User.vue";
import Logo from "@/components/Layout/Header/Logo.vue";
import router from '../router/index'
import { validateCPF, validateCNPJ } from "../services/validateCpfCnpj";

export default {
  components: {
    Header,
    User,
    Logo,
  },
  data() {
    return {
      homeDialog: false,
      messageToast: "",
      showSuccessToast: false,
      showFreeAccountSuccess: false,
      messageSuccToast: "",
      errors: [],
      loader: false,
      loaderSubmit: false,
      mostraForm: true,
      pixModal: false,
      pixUrl: "",
      docSnackbar: false,
      paymentType: null,
      link: "",
      errorDialog: {
        show: false,
        message: '',
        errors: []
      },
      snackbar: {
        type: 'success',
        message: '',
        show: false
      },
      paymentOptions: [
        'Cartão',
        'PIX'
      ],
      form: {
        user: {
          email: "",
          birthdate: "",
          active: true,
          weflow_team: false,
          user_type: "student",
          one_signal_device_id: null,
          address: "",
          zip_code: "",
          complement: "",
          number: "",
          state: "",
          password: "",
          first_name: "",
          last_name: "",
          phone: "",
          city: "",
          gender: "male",
        },
      },
      plan: {},
      plan_installments: [],
      installments: 1,
      creditCard: {
        holderName: "",
        number: "",
        expiryMonth: "",
        expiryYear: "",
        ccv: "",
      },
      creditCardHolder: {
        name: "",
        email: "",
        cpfCnpj: "",
        phone: "",
        postalCode: "",
        addressNumber: "",
      },
      confirmPassword: "",
      totalValue: 0.0,
      planBenefits: [],
      coupon: "",
      couponId: null,
      couponData: null,
      passwordRules: [
        v => v.length >= 6 || 'A senha precisa de no mínimo 6 caracteres.',
      ],
    };
  },
  methods: {
    callSnackbar(type, message) {
      this.snackbar.type = type;
      this.snackbar.message = message;

      this.snackbar.show = true;
    },
    goToLogin() {
      this.homeDialog = false;
      router.push(`/login-user`);
    },
    goToCheckout() {
      router.push(`/success/${this.plan.title.replace(/\s/g, "_")}`);
    },
    clearCoupon() {
      this.coupon = "";
      this.couponId = null;
      this.totalValue = this.plan.value;
      this.couponData = null;
    },
    activateLoader() {
      this.loader = true;
    },
    hideLoader() {
      this.loader = false;
    },
    openModal() {
      this.pixModal = true;
    },
    closeModal() {
      this.pixModal = false;
    },
    validateDocument() {
      let res = true;

      if (this.creditCardHolder.cpfCnpj.length === 14) {
        res = validateCPF(this.creditCardHolder.cpfCnpj);
      }

      if (this.creditCardHolder.cpfCnpj.length === 18) {
        res = validateCNPJ(this.creditCardHolder.cpfCnpj);
      }

      if (!res) {
        this.docSnackbar = true;
      }

      return;
    },
    fetchAddress() {
      if (this.form.user.zip_code.length === 9) {
        this.activateLoader();
        // Make an API request to ViaCEP here
        const viaCEPUrl = `https://viacep.com.br/ws/${this.form.user.zip_code}/json/`;

        fetch(viaCEPUrl)
          .then((response) => response.json())
          .then((data) => {
            this.hideLoader();
            this.form.user.state = data.uf;
            this.form.user.city = data.localidade;
            this.form.user.address = data.logradouro;

            // this.address = data;
          })
          .catch((error) => {
            this.hideLoader();
            console.error(error);
          });
      }
    },
    async checkEmail() {
      if (this.form.user.email.length > 0) {
        this.$api.post('/check_email', {
          "email": this.form.user.email
        }).then(() => {
          this.homeDialog = true;

        }).finally(() => {
          if (this.couponId != null) {
            this.couponId = null;
            this.searchCoupon()
          }
        })
      }

    },
    async getPlano() {
      this.plan_installments = [];
      const id = this.$route.params.idPlano;

      if (id != 0) {
        const resp = await this.$api.get(`/payment_plans/${id}`);

        this.plan = resp.data;
        const benefits = this.plan.benefits.split(";");
        this.planBenefits = benefits;
        this.totalValue = resp.data.value;

        if (resp.data.permit_installments) {
          for (let i = resp.data.min_installments; i <= resp.data.max_installments; i++) {
            this.plan_installments.push(i);
          }

          this.paymentType = 'Cartão'
          this.installments = resp.data.min_installments;
          this.removePixOption();
        }

        return;
      }

      this.plan = {
        id: 0,
        value: 0.0,
        title: "Sem assinatura",
        description: "Libera acesso por 1 mês",
        months: 1,
        // created_at: "2021-04-08T16:59:04.455Z",
        // updated_at: "2023-08-15T13:33:25.490Z",
        user_type: "student",
        stores_id: "student_monthly_rev",
        benefits: "Mudar para a opção sem assinatura cancela seu plano atual",
        permit_installments: false,
        min_installments: 0,
        max_installments: 0
      };

      this.plan_installments = [1];

      const benefits = this.plan.benefits.split(";");
      this.planBenefits = benefits;
    },

    async benefits() {
      const id = this.$route.params.idPlano;
      if (id == 0) {
        this.planBenefits = [
          "Mudar para a opção sem assinatura cancela seu plano atual",
        ];
        return;
      } else {
        this.planBenefits = await this.plan.benefits.split(";");
      }
    },
    async searchCoupon() {
      if (this.couponId != null && this.couponId != "") {
        this.callSnackbar('error', 'Um cupom já foi aplicado');
        return false;
      }

      const id = this.$route.params.idPlano;

      // 
      if (!this.form.user.email) {
        this.callSnackbar('error', 'Para aplicar um cupom, preencha ao menos o email.');

        this.clearCoupon();
        // alert('Para aplicar um cupom, preencha ao menos o email.');
        return;
      }

      if (id != 0) {
        const resp = await this.$api.get(
          `/check_coupon?email=${this.form.user.email
          }&planId=${id}&description=${this.coupon.toUpperCase()}`
        );
        const data = resp.data;

        if (data.body.valid) {

          this.couponId = data.body.id;
          this.couponData = data.body;
          this.applyCoupon(data.body.value, data.body.discount_type);
        } else {
          this.callSnackbar('error', 'Cupom inválido ou inexistente.');
          this.clearCoupon();
        }

      }
    },
    checkIsCouponFull() {
      if (!this.couponData || (this.couponData.value != 100 && this.couponData.discount_type != 'percent')) {
        return false;
      }

      return true;
    },
    applyCoupon(couponVal, couponType) {


      switch (couponType) {
        case "value":
          this.totalValue = this.totalValue - couponVal;
          break;
        default:
          this.totalValue =
            this.totalValue - (couponVal / 100) * this.totalValue;
          break;
      }

      this.callSnackbar('success', 'Cupom aplicado com sucesso!');

    },
    async subscribeCreate(id) {
      if (this.$refs.form.validate()) {
        this.creditCard.number = this.creditCard.number.replace(/\s/g, '');

        const creditCardHolderObj = {
          addressNumber: this.form.user.number,
          cpfCnpj: this.creditCardHolder.cpfCnpj,
          email: this.form.user.email,
          name: this.form.user.first_name,
          phone: this.form.user.phone,
          postalCode: this.form.user.zip_code,
        };

        const object = {
          creating_user: true,
          user_id: id.data.id,
          client: {
            name: this.form.user.first_name,
            email: this.form.user.email,
            phone: this.form.user.phone,
            mobilePhone: this.form.user.phone,
            cpfCnpj: this.creditCardHolder.cpfCnpj,
            postalCode: this.form.user.zip_code,
            address: this.form.user.address,
            addressNumber: this.form.user.number,
            complement: this.form.user.complement,
            province: this.form.user.state,
            externalReference: this.form.user.externalReference,
            notificationDisabled: true
          },
          payment_plan_id: this.$route.params.idPlano,
          payment_object: {
            billingType: this.getPaymentType(),
            creditCard: this.creditCard,
            creditCardHolderInfo: creditCardHolderObj,
          },
          coupon_id: this.couponId,
        };

        if (this.plan.permit_installments) {
          object.payment_object.installmentCount = this.installments;
        }

        this.errors = [];
        // const resp = await this.$api.post('/users/payment_subscribe', object);
        this.$api
          .post("/users/payment_subscribe", object)
          .then(() => {
            //
            this.hideLoader();
            if (this.checkIsCouponFull()) {
              router.push(`/success/${this.plan.title.replace(/\s/g, "_")}`)
              return;
            }


            if (this.paymentType == "PIX") {
              this.$api
                .get("/asaas/" + id.data.id + "/get_payment_link")
                .then((resp) => {
                  this.mostraForm = false;
                  this.link = resp.data.data;
                });

              return;
            } else {
              // this.showSuccessToast = true;
              router.push(`/success/${this.plan.title.replace(/\s/g, "_")}`)
              // this.goToLogin();
            }
          })
          .catch((e) => {
            this.hideLoader();

            this.errorDialog.show = true;
            this.errorDialog.message = 'Algo deu errado. Confira os dados inseridos e tente novamente.';
            this.errorDialog.errors = e.response.status == 500 ? [] : e.response.data.error;
          }).finally(() => {
            this.loaderSubmit = false;
          });

        // if (resp.data) {
        //
        // }
      }
    },

    async submitForm() {

      switch (this.form.user.gender) {
        case "Masculino":
          this.form.user.gender = "male";
          break;
        case "Feminino":
          this.form.user.gender = "female";
          break;
        default:
          this.form.user.gender = "other";
          break;
      }

      const paymentType = this.getPaymentType();

      if (this.form.user.password.length < 6) {
        this.errorDialog.show = true;
        this.errorDialog.message = 'A senha deve conter no mínimo 6 caracteres.';
        this.errorDialog.errors = [];
        // alert("A senha deve conter no mínimo 6 caracteres.");
        return;
      }

      if (this.plan.value > 0.0 && paymentType == 'CREDIT_CARD' && this.creditCard.expiryYear.length < 4) {
        this.errorDialog.show = true;
        this.errorDialog.message = 'O ano de expiração deve ser extenso. Ex: 2029.';
        this.errorDialog.errors = [];
        // alert('O ano de expiração deve ser extenso. Ex: 2029');
        return;
      }

      if (this.form.user.password != this.confirmPassword) {
        this.errorDialog.show = true;
        this.errorDialog.message = 'Senhas diferentes';
        this.errorDialog.errors = [];
        // alert("Senhas diferentes");
        return;
      }

      if (this.$refs.form.validate()) {
        this.activateLoader();
        this.loaderSubmit = true;

        let url = "/users";

        this.$api
          .post(url, {
            user: {
              email: this.form.user.email,
              birthdate: this.form.user.birthdate,
              active: this.form.user.active,
              weflow_team: this.form.user.weflow_team,
              user_type: this.form.user.user_type,
              one_signal_device_id: this.form.user.one_signal_device_id,
              password: this.form.user.password,
              first_name: this.form.user.first_name,
              last_name: this.form.user.last_name,
              phone: this.form.user.phone,
              city: this.form.user.city,
              gender: this.form.user.gender,
              zip_code: this.form.user.zip_code,
              address: this.form.user.address,
            },
          })
          .then((resp) => {
            this.hideLoader();
            
            // 
            if (resp) {
              if (this.plan.value > 0.0) {
                this.subscribeCreate(resp);
              } else {
                this.showFreeAccountSuccess = true;
              } 
            }
          })
          .catch((e) => {
            // 
            this.hideLoader();
            if (e.response.status != 200) {
              this.errorDialog.show = true;
              this.errorDialog.message = '"Algo deu errado. Confira os dados e tente novamente.';
              this.errorDialog.errors = [];
              // alert("Algo deu errado. Confira os dados e tente novamente.");
            }
          }).finally(() => {
            this.loaderSubmit = false;
          });
      }
    },
    getPaymentType() {
      switch (this.paymentType) {
        case "PIX":
          return "PIX";
        case "Boleto":
          return "BOLETO";
        case "Cartão":
          return "CREDIT_CARD";
        default:
          return "CREDIT_CARD";
      }
    },
    removePixOption() {
      if(this.plan.permit_installments) {
        this.paymentOptions = this.paymentOptions.filter(f => f != 'PIX');
      }
    }
  },
  computed: {
    doc_mask() {
      if (this.creditCardHolder.cpfCnpj.length <= 14) {
        return "###.###.###-##"; // CPF mask
      } else {
        return "##.###.###/####-##"; // CNPJ mask
      }
    },
  },
  created() {
    this.getPlano();
  },
};
</script>

<style>
*,
p,
h2,
h3,
h4 {
  font-family: "Roboto", sans-serif !important;
}

.centerCreate {
  padding: 0 30px;
}

.btn-home-modal {
  width: 50%;
  color: white !important;
}

.grid-content {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 0px;
  padding: 2rem;
}

.v-card-home {
  padding-top: 24px;
}

.span-card-home {
  font-size: 18px;
  color: black;
}

.v-card-text-error {
  text-align: center;
}

.error-text {
  color: red !important;
  font-weight: bold
}

.div-error-list {
  margin-top: 10px;
  text-align: justify
}

.footer-error-dialog {
  display: flex;
  justify-content: center
}

@media screen and (max-width: 910px) {
  .grid-content {
    grid-template-columns: 1fr;
    /* Change to 1 column */
    grid-template-rows: 47% 1fr;
    /* Change to 2 rows */
    grid-column-gap: 0px;
    /* No gap between columns */
    grid-row-gap: 10px;
    padding: 1rem;
    /* Adjust padding as needed */
  }

  .doubleInput {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    /* justify-content: space-between; */
  }

  .doubleInput .input {
    width: 100% !important;
  }
}


.container-forms {
  background-color: white;
  padding: 2rem;
  border-radius: 20px;
}

.containerCreate-form {
  background-color: #f8f8f8;
}

.service {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.service .icons i.v-icon.v-icon {
  margin: 0 5px;
}

.icons {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.flex {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  padding: 50px 20px;
}

.doubleInput {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.doubleInput .input {
  width: 49%;
}

.tripleInput {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.tripleInput .input {
  width: 33.3% !important;
  margin-right: 10px;
}

.card-plan {
  background-color: white;
  border-radius: 10px;
}

.cupom-applied {
  border: 1px solid green;
}

.card-form {
  padding: 25px;
  background-color: white;
  border-radius: 10px;
}

.payment-info {
  color: #b1b1b1;
  font-size: 12px;
}

.cardSubscription-flat-listItem {
  padding: 0px;
  min-height: unset;
  background: #f7f6f3;
  border-radius: 10px;
  padding: 25px;
}

.cardSubscription-flat-listItem .typePlan {
  padding: 5px 0;
  background-color: #e2efff;
  color: #1876d0;
  font-size: 10px;
  text-align: center;
  border-radius: 10px;
}

.containerContent-coupon-contact {
  max-height: 100%;
  overflow: auto;
}

.containerContent-coupon-contact .v-label {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.benefits {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.containerForm-input .v-input__slot {
  border-radius: 8px;
  box-shadow: none !important;
  background: #eaeaea !important;
  margin-bottom: 0px !important;
  width: 100%;
}

.containerForm-btn {
  min-height: 50px;
  background-color: #cef9c6 !important;
  border-radius: 25px;
  box-shadow: none;
  margin-bottom: 55px;
}

.cardSubscription-price {
  background: #fff8dd;
  border-radius: 10px;
  padding: 18px 0;
  margin-bottom: 20px;
}

.bgPrice {
  background: #c5e9c3 !important;
}

.margin-top {
  margin: 20px 0;
}

.flex {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 10px;
}

.flex-print {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.printBtn .v-btn {
  width: 40% !important;
  min-width: none;
}
</style>

<style>
@media screen and (max-width: 910px) {
  .flex {
    flex-direction: column;
    align-items: center;
  }

  .card-form {
    width: 100%;
    margin-top: 10px;
  }

  .card-plan {
    width: 100%;
    margin-top: 10px;
  }

  .centerCreate {
    padding: 0;
  }
}
</style>
