<template>
    <v-container>
        <v-card :loading="loading" class="mx-auto my-12" max-width="374">
            <template slot="progress">
                <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
            </template>
            <v-img height="100" class="logo-form" src="/assets/logo_green.png"></v-img>

            <v-form @submit.prevent="submit" ref="form">
                <v-card-text>
                    <v-alert type="success" v-if="successMsg">
                        Subcategoria cadastrada com sucesso!
                    </v-alert>
                    <v-row align="center" class="mx-0">
                        <v-text-field v-model="form.description" label="Título"
                            :rules="[(v) => !!v || 'Título é obrigatório']" hide-details="auto"></v-text-field><br />

                        <v-text-field v-model="form.order" label="Número de ordernação" type="number"
                            :rules="[(v) => !!v || 'Número de ordernação é obrigatório']"
                            hide-details="auto"></v-text-field>

                    </v-row>

                </v-card-text>

                <v-card-text>
                    <v-btn block type="submit" color="primary">
                        <span v-if="loading"> Carregando </span>
                        <span v-else> Enviar </span>
                    </v-btn>
                </v-card-text>
            </v-form>
        </v-card>

        <v-card class="mx-auto my-12" max-width="374">
                    <v-card-title>
                        Imagem
                    </v-card-title>
                    <v-card-image>
                        <img :src="image" width="100%" />
                    </v-card-image>
                    <v-card-text>
                        <UploadSubcategoryComponent fieldname="subcategory[image]" />
                    </v-card-text>
                </v-card>
    </v-container>
</template>


<script>
import { mapActions } from "vuex";
import UploadSubcategoryComponent from '@/components/UploadSubcategoryComponent';

export default {
    components: {
        UploadSubcategoryComponent
    },
    mounted() {
        this.retrieveSubcategory()
    },
    methods: {
        ...mapActions("User", ["LOGIN_USER"]),
        async retrieveSubcategory() {
            const resp = await this.$api.get(`/subcategories/${this.$route.params.subcategory_id}`)
            if (resp.status == 200) {
                this.form = { ...resp.data }
                this.image = resp.data.image
            } else {
                alert("erro")
            }
        },
        async submit() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                let id = this.$route.params.id;
                let url =
                    `/subcategories/${id}`;
                console.log(this.form);

                const resp = await this.$api.put(url, {
                    subcategory: { ...this.form },
                });
                if (resp.status === 200) {
                    this.loading = false;
                    this.successMsg = true;
                } else {
                    this.loading = false;
                }
            }
        },
    },
    data: () => ({
        formValid: true,
        loading: false,
        successMsg: false,
        validations: {},
        image: null,
        form: {
            description: "",
            order: 0,
            class_categories_id: 0
        },
    }),
};
</script>

<style scoped>
.logo-form {
    width: 100px;
    margin-left: 35%;
    margin-top: 20px;
    height: 100px;
}
</style>