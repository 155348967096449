<template>
    <Comments />
</template>

<script>
    import Comments from '@/components/ClassItem/comments';
    
    export default {
        components: {
            Comments
        }
    }
</script>