<template>
    <ListClassItem />
</template>

<script>
import ListClassItem from '@/components/ListClassItem.vue';
export default {    
    components: {
        ListClassItem
    }
}
</script>