<template>
  <div class="logo-container">
    <v-img
      height="50"
      width="50"
      :src="require('@/assets/logo_green.png')"
      
    ></v-img>
    <p class="logo-title"> We Flow</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>
  .logo-title {
    color: #72D34C;
    font-size: 29px;
    font-weight: bolder;
    padding-left: 1rem;
    margin: 0 !important;
  }

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

</style>