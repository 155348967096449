<template>
    <ListPlans />
</template>


<script>
  import ListPlans from "@/components/Plans/ListPlans";
  export default {
      components: {
          ListPlans
      }
  }
</script>