<template>
  <ReportCoupon />
</template>

<script>
import ReportCoupon from "@/components/Coupons/ReportCoupon";
export default {
  components: {
    ReportCoupon,
  },
};
</script>
