<template>
  <v-navigation-drawer absolute permanent left id="sideMenu" class="containerSideMenu">
    <v-list>
      <!-- <v-list-item class="mobile"> -->
      <!-- <v-list-item-content> -->
      <!-- <v-list-item-title><User /></v-list-item-title> -->
      <!-- </v-list-item-content> -->
      <!-- </v-list-item> -->
      <v-list-item class="mobile">
        <v-list-item-content>
          <v-list-item-title class="containerSideMenu-item">Menu</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <router-link class="containerSideMenu-item-link" :to="item.path" v-for="(item, index) in menuItems" :key="index">
        <v-list-item>
          <v-list-item-icon>
            <v-img height="20" width="20" :src="item.icon"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  components: {
    // User
  },
  data() {
    return {
      drawer: false,
      menuItems: [
        { icon: require('@/assets/icon-account.svg'), title: 'Conta', path: '/account-v2', needSubscription: false, subType: '' },
        { icon: require('@/assets/icon-manageSubscription.svg'), title: 'Gerenciar assinatura', path: '/manageSubscription-v2', needSubscription: false, subType: 'web' },
        { icon: require('@/assets/icon-paymentMethods.svg'), title: 'Formas de pagamento', path: '/payment-methods-v2', needSubscription: true, subType: 'web' },
        { icon: require('@/assets/icon-coupon.svg'), title: 'Cupom', path: '/couponSubscription', needSubscription: false, subType: '' },
        { icon: require('@/assets/icon-support.svg'), title: 'Suporte', path: '/support-v2', needSubscription: false, subType: '' }
      ]
    };
  },
  methods: {
    async filterMenu() {
      const user = JSON.parse(localStorage.getItem('user'));
      const plan = JSON.parse(localStorage.getItem('plan'));

      if (!plan || plan === 'null') {
        this.menuItems = this.menuItems.filter(m => !m.needSubscription);
      } else {
        const isWebPayment = user && user.payment_type === 'web';
        this.menuItems = await this.menuItems.filter(m => m.path !== '/couponSubscription');

        if (!isWebPayment || plan.is_unique) {
          this.menuItems = this.menuItems.filter(m => m.subType !== 'web' || m.subType === '');
        }

        if(!!plan && plan.is_expired) {
          this.menuItems = this.menuItems.filter(m => m.path !== '/payment-methods-v2');
        }
      }
    }
  },
  created() {
    this.filterMenu();
  },

};
</script>
<style>
.containerSideMenu {
  height: calc(100vh - 110px) !important;
  position: unset !important;
  background: #1B1B1B !important;
}

.containerSideMenu .v-list-item__title {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.containerSideMenu .v-list-item__icon {
  min-width: 20px;
  margin: 0 !important;
}

.containerSideMenu .v-list-item {
  padding: 0px;
  min-height: unset;
}

.containerSideMenu .v-list-item__content {
  padding: 0px;
}

.containerSideMenu-item-link {
  height: 55px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-left: 30px;
  position: relative;
}

.containerSideMenu-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-left: 30px;
  margin: 20px 0 0;
  font-size: 12px;
  color: #878787 !important;
}

.containerSideMenu-item-link .v-list-item {
  gap: 10px;
}

.containerSideMenu-item-link.router-link-active {
  background: #3B3B3B;
}

.containerSideMenu-item-link.router-link-active::before {
  content: '';
  width: 5px;
  height: 100%;
  background: #4EBA46;
  position: absolute;
  left: 1px;
}

@media (min-width: 1023px) {
  .containerSideMenu-item-link:hover {
    background: #3B3B3B;
  }

  .containerSideMenu-item-link:hover::before {
    content: '';
    width: 5px;
    height: 100%;
    background: #4EBA46;
    position: absolute;
    left: 1px;
  }
}

@media (max-width: 1023px) {
  .containerSideMenu {
    height: calc(100vh) !important;
    position: absolute !important;
    z-index: 30 !important;
  }

  .containerSideMenu .containerHeader-user {
    justify-content: center;
    margin-top: 20px;
  }

  #sideMenu {
    display: none;
  }

}
</style>
