<template>
  <CreateLevel />
</template>


<script>
  import CreateLevel from "@/components/Levels/CreateLevel";
  export default {
      components: {
          CreateLevel
      }
  }
</script>