<template>
  <v-container>
    <v-card :loading="loading" class="mx-auto my-12" max-width="374">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <v-container>
          <h2>Alterar Senha</h2>
      </v-container>

      <v-form @submit.prevent="submit" ref="form">
        <v-card-text>
          <v-alert type="error" v-if="error">
            Ocorreu um erro ao recuperar sua senha
          </v-alert>
          <div v-if="successMsg">
            <v-alert type="success" >
              Senha foi alterada com sucesso!
            </v-alert>
            <v-btn @click="$router.push(`/editUser/${$route.params.id}`)">
              <v-icon>
                mdi-arrow-left-circle
              </v-icon>
              Voltar
            </v-btn>
          </div>
          
          <v-row v-else align="center" class="mx-0">
            <v-text-field
              v-model="form.password"
              label="Nova Senha"
              type="password"
              :rules="[(v) => !!v || 'Senha é obrigatório']"
              hide-details="auto"
            ></v-text-field><br />
                       
            <v-text-field
              v-model="form.passwordConfirm"
              label="Repita a nova senha"
              type="password"
              :rules="[
                (v) => !!v || 'Repetição de senha é obrigatório',
                (v) => v === form.password || 'Senhas não são iguais'
              
              ]"
              hide-details="auto"
            ></v-text-field>
          
          </v-row>
        </v-card-text>

        <v-card-text v-if="!successMsg">
          <v-btn block type="submit" color="primary">
            <span v-if="loading"> Carregando </span>
            <span v-else> Salvar </span>
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>


<script>

export default {
  mounted() {
     this.getUsers();
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let url =
          `/users/${this.$route.params.id}`;

        const resp = await this.$api.put(url, {
          user: {
            password: this.form.password,
          },
        });
        if (resp.data) {
          this.loading = false;
          this.successMsg = true;
        } else {
          this.loading = false;
        }
        console.log(resp);
      }
    },
  },
  data: () => ({
    formValid: true,
    loading: false,
    successMsg: false,
    changePassword: false,
    validations: {},
    form: {
      password: "",
    },
  }),
};
</script>

<style scoped>
.logo-form {
  width: 100px;
  margin-left: 35%;
  margin-top: 20px;
  height: 100px;
}
</style>