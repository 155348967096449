<template>
  <v-container>
    <v-btn color="success" @click="$router.push('/createPlan')">
      Adicionar Plano
      <v-icon>
        mdi-plus
      </v-icon>
    </v-btn>
    <v-alert v-if="message !== ''" type="success" >
      {{message}}
    </v-alert>
    <br />
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="plans"
      :items-per-page="5"
      class="elevation-1"
    >
    <template v-slot:item.image="{ item }">
      <img width="150" height="100" :src="item.image" />
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="$router.push(`/editPlan/${item.id}`)"
      >
        mdi-pencil
      </v-icon>
      <ModalDelete v-if="item.deletable" @click="deletePlan(item.id)" />
    </template>    
    </v-data-table>
  </v-container>
</template>
<script>
import ModalDelete from '@/components/shared/ModalDelete';

export default {
  components: {
    ModalDelete
  },
  methods: {
    async getPlans(){
      const resp = await this.$api.get('/payment_plans')
      if(resp.status == 200) {
        this.plans = resp.data
      }else{
        alert("erro ao buscar planos")
      }
    },
    async deletePlan(planId){
      let url = `payment_plans/${planId}`;

      this.$api.delete(url).then(() => {
        this.plans = this.plans.filter((item) => item.id !== planId)
        this.message = "Plano excluída com sucesso!"
        setTimeout(() => {
          this.message = ""
        }, 1200)
      }).catch(err=> {
        console.log(err)
        alert("erro ao deletar");
      }).finally(() => this.dialog = false);

    }
  },
  data() {
    return {
      plans: [],
      loading: false,
      message: "",
      headers: [
        { text: "Id", value: "id" },
        { text: "Titulo", value: "title" },
        { text: "Descrição", value: "description" },
        { text: "Recorrência", value: "months" },
        { text: "Valor", value: "value" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  async mounted() {
    this.loading = true
    await this.getPlans()
    this.loading = false
  },
};
</script>