<template>
  <div>
    <Layout>
      <template #content>
        <div class="containerContent-support">
          <h1 class="containerContent-support-title">
            Suporte
          </h1>
          <p class="containerContent-support-desc">
            Contate nosso suporte através do e-mail
          </p>
          <div class="containerContent-support-contact">
            <span class="containerContent-support-email">suporte@weflowoficial.com</span>
          </div>
        </div>
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from '@/layouts/HomeLayout.vue';

export default {
  components: {
    Layout
  }
};
</script>
<style scoped>
.containerContent-support {
  max-width: 730px;
  width: 100%;
  margin: 0 auto;
  height: calc(100% - 40px);
  background: #fff;
  border-radius: 8px;
  margin-top: 40px;
  padding: 30px;
}
.containerContent-support-title {
  font-size: 26px;
  padding: 0px;
  font-weight: bold;
}
.containerContent-support-desc {
  font-size: 16px;
  margin-bottom: 40px;
}
.containerContent-support-contact {
  background-image: url(../assets/content_support_email.png);
  background-repeat: no-repeat;
  background-position: center;
  min-height: 92px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 75px;
}
.containerContent-support-email {
  font-size: 16px;
  font-weight: bold;
}

@media (max-width: 1023px) {
  .containerContent-support-contact {
    background-position: unset;
    padding-left: 60px;
  }
}
</style>