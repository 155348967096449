<template>
  <div class="containerLogin">
    <LoginForm />
  </div>
</template>


<script>
import LoginForm from '@/components/LoginForm-v2';

export default {
components: {
  LoginForm
}
};
</script>

<style scoped>
.logo-form {
width: 100px;
margin-left: 35%;
margin-top: 20px;
height: 100px;
}
.containerLogin {
  background: #000000;
  height: 100vh;
}

</style>
<style>
.containerLogin .containerLogin-form {
  height: 100%;
  display: grid;
  place-items: center;
}
</style>