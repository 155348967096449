<template>
    <Checkout />
</template>

<script>
  import Checkout from "@/components/Checkout";

  export default {
      components: {
        Checkout
      }
  }
</script>

