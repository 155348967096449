<template>
    <EditCoupon />
</template>
  
  
<script>
import EditCoupon from "@/components/Coupons/EditCoupon";
export default {
    components: {
        EditCoupon
    }
}
</script>