<template>
    <EditSubcategory />
</template>


<script>
import EditSubcategory from "../../components/Subcategories/EditSubcategory.vue";
  export default {
      components: {
          EditSubcategory
      }
  }
</script>