import Vue from 'vue'

// const createNewUser = async payload =>
//   Vue.prototype.$api.post('/users', payload)

// const getUser = async () => Vue.prototype.$api.get('/users')

const getClassItem = async (id) =>
  Vue.prototype.$api.get(`/class_items/${id}`)

const getClassCategories = async () =>
  Vue.prototype.$api.get('/class_categories')

const getClassCategoriesSubcategories = async (id) => 
  Vue.prototype.$api.get(`/subcategories_by_category/${id}`);

// const updateUser = async (userId, payload) =>
//   Vue.prototype.$api.put(`/users/${userId}`, {user: payload})

// const deleteUser = async (userId) =>
//   Vue.prototype.$api.delete(`/users/${userId}`)

// const logoutUser = async () =>
//   Vue.prototype.$api.post(
//     '/auth/logout',
//     {},
//     {
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//       },
//     }
//   )

export { 
  getClassCategories,
  getClassItem,
  getClassCategoriesSubcategories
}
