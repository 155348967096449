<template>
    <EditCategory />
</template>


<script>
  import EditCategory from "@/components/Categories/EditCategory";
  export default {
      components: {
          EditCategory
      }
  }
</script>