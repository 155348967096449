<template>
  <div class="container-homeLayout">
    <Header>
      <template #logo>
        <Logo />
      </template>
      <template #user>
        <User />
      </template>
      <template class="mobile" #menu>
        <OpenMenu />
      </template>
    </Header>
    <v-conntainer class="d-flex">
      <div class="containerLayout-sideMenu">
        <SideMenu />
      </div>
      <div class="containerLayout-content">
        <slot name="content" />
      </div>
    </v-conntainer>
    <div id="overlaySideMenu" @click="closeSideMenu" />
  </div>
</template>

<script>
import Header from '@/components/Layout/Header/Header.vue';
import User from '@/components/Layout/Header/User/User.vue';
import Logo from '@/components/Layout/Header/Logo.vue';
import SideMenu from '@/components/Layout/Menu/SideMenu.vue';
import OpenMenu from '@/components/Layout/Menu/OpenMenuMobile.vue';

export default {
  components: {
    Header,
    User,
    Logo,
    SideMenu,
    OpenMenu
  },
  methods: {
    closeSideMenu() {
      document.getElementById('sideMenu').classList.remove('openSideMenu');
      document.getElementById('overlaySideMenu').classList.remove('openSideMenu');
    }
  },
}
</script>

<style scoped>
.containerLayout-content {
  width: 100%;
  height: calc(100vh - 110px);
  background: #F8F8F8;
}

.mobile {
  display: none;
}

@media (max-width: 1023px) {
  .containerLayout-content {
    padding: 0 15px;
  }

  #overlaySideMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: none;
  }

  .mobile {
    display: block;
  }
}


.containerLayout-content::-webkit-scrollbar {
  display: none;
}

@media (max-height: 950px) {
  .containerLayout-content {
    max-height: 800px;
  }
}

@media (max-height: 840px) {
  .containerLayout-content {
   /* max-height: 600px; */
  }
}

@media (max-height: 600px) {
  .containerLayout-content {
    max-height: 500px;
  }
}

@media (max-width: 1023px) {
  .containerLayout-content {
    max-height: 100% !important;
  }
}

@media (min-width: 1024px) {
  .containerLayout-content {
    overflow: auto;
  }
}
</style>