import Vue from "vue";
// import axios from "axios";
// const BASE_URL = "http://localhost:3000/api/v1";

const createNewUser = async (payload) =>
  Vue.prototype.$api.post("/users", payload);

const getUser = async () => Vue.prototype.$api.get("/users");

const loginUser = async (payload) =>
  Vue.prototype.$api.post("/auth/login", payload);

const recoveryPassword = async (payload) =>
  Vue.prototype.$api.post("/require_recovery_password", payload);

const loginUserDashboard = async (payload) =>
  Vue.prototype.$api.post("/auth/login_admin", payload);

async function updateUser(payload, userId) {
  // const url = `${BASE_URL}/users/${userId}`;
  // console.log(url);
  console.log(userId);
  const token = await localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  console.log(token);

  return Vue.prototype.$api
    .put(`/users/${userId}`, payload, headers)
    .then((x) => x.data);
}
async function createUser({ commit }, payload) {
  // const url = `${BASE_URL}/users`;
  console.log(commit);

  return Vue.prototype.$api.post("users", payload).then((x) => x.data);
}

const deleteUser = async (userId) =>
  Vue.prototype.$api.delete(`/users/${userId}`);

const logoutUser = async () =>
  Vue.prototype.$api.post(
    "/auth/logout",
    {},
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );

export {
  createNewUser,
  deleteUser,
  getUser,
  recoveryPassword,
  loginUserDashboard,
  loginUser,
  logoutUser,
  updateUser,
  createUser,
};
