<template>
    <ListLevels />
</template>


<script>
  import ListLevels from "@/components/Levels/ListLevels";
  export default {
      components: {
          ListLevels
      }
  }
</script>