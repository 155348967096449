<template>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <div small v-bind="attrs" v-on="on">
                <slot @click="openModal" name="activator"></slot>
            </div>

        </template>
        <v-card>
            <div class="title-flex" v-if="!cancel">
                <v-card-title class="title">
                    Trocar plano
                </v-card-title>

                <v-icon @click="destroyComponent">mdi-close</v-icon>
            </div>

            <div class="title-flex" v-if="cancel">
              <v-card-title class="title">
                Sua assinatura atual será cancelada
              </v-card-title>

              <v-icon @click="destroyComponent">mdi-close</v-icon>
            </div>

            <v-card-text class="body" v-if="!cancel">
                Sua assinatura será trocada para o plano {{ plansObj.title }} no início do próximo ciclo de assinatura.
            </v-card-text>

            <v-card-text class="body" v-if="cancel">
                Deseja continuar?
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn block type="button" :loading="loadingModal" @click="handleConfirm" color="containerForm-btn">
                    <template>
                        Confirmar
                    </template>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
export default {
    props: ['plansObj', 'cancel'],
    methods: {
        click() {
            this.$emit("click");
            this.dialog = false;
        },
        openModal(planObj) {
            console.log('teste', planObj);
        },

        destroyComponent() {
            this.dialog = false
            this.$nextTick(() => {
                console.log('destruido');
            });
        },
        handleConfirm() {
            if (this.cancel) {
                this.cancelSubscription();
            } else {
                this.changeSubscription();
            }
        },
        cancelSubscription() {
            const user = JSON.parse(localStorage.getItem('user'))
            this.$api.get('/asaas/' + user.id + '/cancel_subscription').then((resp) => {
                // 
                if(resp.status == 200) {
                    this.$emit('closedModal', true);
                } else {
                    this.$emit('closedModal', false);
                }

                this.dialog = false;
            }).catch((e) => {
                this.$emit('closedModal', false);
                this.dialog = false;
                console.log('e', e);
            });
        },
        changeSubscription() {
            // this.$emit('closedModal', true);
            // this.dialog = false;
            this.loadingModal = true;

            const user = JSON.parse(localStorage.getItem('user'))
            this.$api.post('/asaas/' + user.id + '/change_subscription', {
                plan_id: this.plansObj.id
            }).then((resp) => {
                // 
                if(resp.status == 200) {
                    this.$emit('closedModal', true);
                } else {
                    this.$emit('closedModal', false);
                }

                this.loadingModal = false;
                this.dialog = false;
            }).catch((e) => {
                this.$emit('closedModal', false);
                this.loadingModal = false;
                this.dialog = false;
                console.log('e', e);
            });
        }
    },
    data() {
        return {
            dialog: false,
            receivedPlanObj: null,
            loadingModal: false,
        }
    },
    created() {
        if (this.plansObj == null) {
            this.plansObj = {
                benefits: "",
                deletable: "",
                description: "",
                id: 1,
                months: 1,
                title: "",
                user_type: "",
                value: "",
            }
        }
    },
    watch: {
        plansObj() {
            console.log('A variável foi modificada:', this.plansObj);
            // Aqui você pode executar qualquer ação quando a variável for modificada
        }
    }
}
</script>

<style>
.title-flex {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 0 10px;
    align-items: baseline;
}

.title-flex v-icon {
    margin: 0 10px;
}

.title {
    background-color: white;
}

.body {
    padding: 30px !important;
    color: black !important;
    font-weight: bold !important;
    font-size: 20px !important;
    text-align: center;
}

.containerForm-btn {
    min-height: 50px;
    background-color: #CEF9C6 !important;
    border-radius: 25px;
    box-shadow: none;
    margin: 10px auto !important;
}
</style>
