<template>
    <UpdatePassword />
</template>

<script>
import UpdatePassword from "@/components/Users/UpdatePassword";

export default {
    components: {
        UpdatePassword
    }
}
</script>