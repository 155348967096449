<template>
  <div>
    <Layout>
      <template #content>
       <div class="containerAccountEdit">
          <FormEdit />
       </div>
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from '@/layouts/HomeLayout.vue';
import FormEdit from '@/components/Layout/Account/Edit/FormEdit.vue';

export default {
  components: {
    Layout,
    FormEdit
  }
};
</script>
<style scoped>
.containerAccount {
  background: #FFFFFF;
  padding: 35px;
  margin: 40px 30px 0;
  display: flex;
  justify-content: space-between;
}
</style>