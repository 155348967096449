<template>
    <EditUserForm />
</template>

<script>
  import EditUserForm from "@/components/Users/EditUserForm";

  export default {
    components: {
      EditUserForm
    }
  }
</script>