<template>
    <LoginForm />
</template>


<script>
import LoginForm from '@/components/LoginForm';

export default {
  components: {
    LoginForm
  }
};
</script>

<style scoped>
.logo-form {
  width: 100px;
  margin-left: 35%;
  margin-top: 20px;
  height: 100px;
}
</style>